import React from 'react';
import { NavLink } from 'react-router-dom';
import IconChevron from '!svg-react-loader?name=IconChevron!assets/svg/icon-chevron-left.svg';
import { filter } from 'lodash/fp';
import { testDeviceMenu } from '@utils/devices';
const getLastActiveItem = (example) => {
    console.log('example=', example);
    const activeItem = document.getElementById(`device-nav-example-${example}`)?.querySelectorAll('li.last-active')[0];
    console.log('activeItem=', activeItem);
    activeItem && activeItem.classList.remove('last-active');
    const activeItems = document.getElementById(`device-nav-example-${example}`)?.querySelectorAll('li.active');
    const latestActiveItem = activeItems[activeItems.length - 1];
    console.log('latestActiveItem=', latestActiveItem);
    latestActiveItem && latestActiveItem.classList.add('last-active');
};
export const onClickItem = (id, example) => {
    const navElement = document.getElementById(`device-nav-example-${example}`)?.getElementsByClassName(`example-${example}`)[0];
    const listElement = document.getElementById(`device-nav-example-${example}`)?.getElementsByClassName(`device-menu-${id}`)[0];
    if (listElement && listElement.classList && listElement.classList.contains('active')) {
        listElement.classList.remove('active');
    }
    else {
        const elementItems = navElement && navElement.querySelectorAll('li.active');
        elementItems && elementItems.forEach((item) => {
            item.classList.remove('active');
        });
        listElement && listElement.classList && listElement.classList.add('active');
    }
    getLastActiveItem(example);
};
export const onClickSubItem = (navId, id, example) => {
    const subNavElement = document.getElementById(`sub-nav-${navId}`);
    const subListElement = document.getElementById(`device-nav-example-${example}`)?.getElementsByClassName(`device-menu-${id}`)[0];
    if (subListElement && subListElement.classList && subListElement.classList.contains('active')) {
        subListElement.classList.remove('active');
    }
    else {
        const subElementItems = subNavElement && subNavElement.querySelectorAll('li.active');
        subElementItems && subElementItems.forEach((item) => {
            item.classList.remove('active');
        });
        subListElement && subListElement.classList && subListElement.classList.add('active');
    }
    getLastActiveItem(example);
};
const SubMenuTestMenu = (props) => {
    const { example } = props;
    const deviceMenu = testDeviceMenu;
    let mainParentKey = 0;
    let mainParentStartKey = 0;
    let mainParentEndKey = 0;
    let level = 0;
    let levelStartKey = 0;
    let levelEndKey = 0;
    let prevMenuItem = {};
    let level2EndKey = 0;
    let level3EndKey = 0;
    let level4EndKey = 0;
    let level5EndKey = 0;
    let level6EndKey = 0;
    let level7EndKey = 0;
    let level8EndKey = 0;
    let level9EndKey = 0;
    let level10EndKey = 0;
    let newsubMenu = [];
    const newMenu = deviceMenu &&
        deviceMenu.length > 0 &&
        deviceMenu.map((item) => {
            const hasChildren = item.L + 1 !== item.R;
            const isNewMainParent = mainParentEndKey < item.L;
            if (isNewMainParent) {
                mainParentKey++;
                mainParentStartKey = item.L;
                mainParentEndKey = item.R;
                level = 1;
                newsubMenu = [];
            }
            const isNewSubParent = levelEndKey < levelStartKey;
            !isNewMainParent && isNewSubParent && hasChildren && level++;
            if (!isNewMainParent && isNewSubParent) {
                level = 2;
                level2EndKey = item.R;
            }
            const isChildOfPrev = item.L > prevMenuItem.l && item.L < prevMenuItem.r;
            if (isChildOfPrev) {
                levelStartKey = item.L;
                levelEndKey = item.R;
                level++;
            }
            if (level === 2) {
                level2EndKey = item.R;
            }
            else if (level === 3) {
                level3EndKey = item.R;
            }
            else if (level === 4) {
                level4EndKey = item.R;
            }
            else if (level === 5) {
                level5EndKey = item.R;
            }
            else if (level === 6) {
                level6EndKey = item.R;
            }
            else if (level === 7) {
                level7EndKey = item.R;
            }
            else if (level === 8) {
                level8EndKey = item.R;
            }
            else if (level === 9) {
                level9EndKey = item.R;
            }
            else if (level === 10) {
                level10EndKey = item.R;
            }
            if (level >= 2 && level2EndKey < item.L) {
                level2EndKey = item.R, level = 2;
            }
            else if (level >= 3 && level3EndKey < item.L) {
                level3EndKey = item.R, level = 3;
            }
            else if (level >= 4 && level4EndKey < item.L) {
                level4EndKey = item.R, level = 4;
            }
            else if (level >= 5 && level5EndKey < item.L) {
                level5EndKey = item.R, level = 5;
            }
            else if (level >= 6 && level6EndKey < item.L) {
                level6EndKey = item.R, level = 6;
            }
            else if (level >= 7 && level7EndKey < item.L) {
                level7EndKey = item.R, level = 7;
            }
            else if (level >= 8 && level8EndKey < item.L) {
                level8EndKey = item.R, level = 8;
            }
            else if (level >= 9 && level9EndKey < item.L) {
                level9EndKey = item.R, level = 9;
            }
            else if (level >= 10 && level10EndKey < item.L) {
                level10EndKey = item.R, level = 10;
            }
            const menuObject = {
                mainParentKey,
                name: item.Name,
                l: item.L,
                r: item.R,
                level,
                prefix: item.Prefix,
                hasChildren,
                subMenu: hasChildren ? newsubMenu : []
            };
            item.L > mainParentStartKey && item.L < mainParentEndKey && newsubMenu.push(menuObject);
            if (level < 2) {
                menuObject.subMenu = newsubMenu;
            }
            else {
                menuObject.subMenu = [];
            }
            prevMenuItem = menuObject;
            return menuObject;
        });
    const groupedMenuObjects = newMenu && newMenu.reduce((result, obj) => {
        (result[obj.mainParentKey] = result[obj.mainParentKey] || []).push(obj);
        return result;
    }, []);
    return (React.createElement("div", { className: 'device-menu sub-menu', id: `device-nav-example-${example}` },
        React.createElement("div", { className: 'sub-menu-header' },
            "Menu #",
            example),
        React.createElement("div", { className: 'menu-with-submenu' },
            React.createElement("ul", { id: "device-nav", className: `example-${example} content-level-1` }, groupedMenuObjects &&
                groupedMenuObjects.length > 0 &&
                groupedMenuObjects.map((item, key) => {
                    const childrenOfLevel2 = filter((o) => { return o.level === 2; }, item);
                    return item[0].level === 1 && (React.createElement("li", { key: key, id: `device-menu-${item[0].l}-${item[0].r}`, className: `device-menu-${item[0].l}-${item[0].r} ${item[0].level ? `level-${item[0].level}` : ''}` },
                        React.createElement(NavLink, { to: '#', onClick: () => onClickItem(`${item[0].l}-${item[0].r}`, example), className: 'parent-item' },
                            React.createElement("p", null, item[0].name),
                            item[0].hasChildren && React.createElement("div", { className: 'chevron parent-chevron' },
                                React.createElement(IconChevron, null))),
                        childrenOfLevel2 && childrenOfLevel2.length > 0 &&
                            React.createElement("ul", { id: `sub-nav-${item[0].l}-${item[0].r}`, className: `sub-nav-${item[0].l}-${item[0].r} content-level-2` },
                                React.createElement("span", { className: 'submenu-triangle level-2' }),
                                React.createElement("span", { className: 'submenu-bar submenu-bar-top' }),
                                childrenOfLevel2.map((item2, key2) => {
                                    const childrenOfLevel3 = filter((o) => { return o.level === 3 && o.l > item2.l && o.r < item2.r; }, item);
                                    return (React.createElement("li", { key: key2, id: `device-menu-${item2.l}-${item2.r}`, className: `device-menu-${item2.l}-${item2.r} ${item2.level ? `level-${item2.level}` : ''}` },
                                        React.createElement(NavLink, { to: '#', className: `sub-item level-${item2.level}`, onClick: () => onClickSubItem(`${item[0].l}-${item[0].r}`, `${item2.l}-${item2.r}`, example) },
                                            React.createElement("p", null, item2.name),
                                            item2.hasChildren && React.createElement("div", { className: 'chevron sub-chevron' },
                                                React.createElement(IconChevron, null))),
                                        childrenOfLevel3 && childrenOfLevel3.length > 0 &&
                                            React.createElement("ul", { id: `sub-nav-${item2.l}-${item2.r}`, className: `sub-nav-${item2.l}-${item2.r} content-level-3` },
                                                React.createElement("span", { className: 'submenu-triangle level-3' }),
                                                React.createElement("span", { className: 'submenu-bar submenu-bar-top' }),
                                                childrenOfLevel3.map((item3, key3) => {
                                                    const childrenOfLevel4 = filter((o) => { return o.level === 4 && o.l > item3.l && o.r < item3.r; }, item);
                                                    return (React.createElement("li", { key: key3, id: `device-menu-${item3.l}-${item3.r}`, className: `device-menu-${item3.l}-${item3.r} ${item3.level ? `level-${item3.level}` : ''}` },
                                                        React.createElement(NavLink, { to: '#', className: `sub-item level-${item3.level}`, onClick: () => onClickSubItem(`${item2.l}-${item2.r}`, `${item3.l}-${item3.r}`, example) },
                                                            React.createElement("p", null, item3.name),
                                                            item3.hasChildren && React.createElement("div", { className: 'chevron sub-chevron' },
                                                                React.createElement(IconChevron, null))),
                                                        childrenOfLevel4 && childrenOfLevel4.length > 0 &&
                                                            React.createElement("ul", { id: `sub-nav-${item2.l}-${item2.r}`, className: `sub-nav-${item2.l}-${item2.r} content-level-4` },
                                                                React.createElement("span", { className: 'submenu-triangle level-4' }),
                                                                React.createElement("span", { className: 'submenu-bar submenu-bar-top' }),
                                                                childrenOfLevel4.map((item4, key4) => {
                                                                    const childrenOfLevel5 = filter((o) => { return o.level === 5 && o.l > item4.l && o.r < item4.r; }, item);
                                                                    return (React.createElement("li", { key: key4, id: `device-menu-${item4.l}-${item4.r}`, className: `device-menu-${item4.l}-${item4.r} ${item4.level ? `level-${item4.level}` : ''}` },
                                                                        React.createElement(NavLink, { to: '#', className: `sub-item level-${item4.level}`, onClick: () => onClickSubItem(`${item3.l}-${item3.r}`, `${item4.l}-${item4.r}`, example) },
                                                                            React.createElement("p", null, item4.name),
                                                                            item4.hasChildren && React.createElement("div", { className: 'chevron sub-chevron' },
                                                                                React.createElement(IconChevron, null))),
                                                                        childrenOfLevel5 && childrenOfLevel5.length > 0 &&
                                                                            React.createElement("ul", { id: `sub-nav-${item2.l}-${item2.r}`, className: `sub-nav-${item2.l}-${item2.r} content-level-5` },
                                                                                React.createElement("span", { className: 'submenu-triangle level-5' }),
                                                                                React.createElement("span", { className: 'submenu-bar submenu-bar-top' }),
                                                                                childrenOfLevel5.map((item5, key5) => {
                                                                                    return (React.createElement("li", { key: key5, id: `device-menu-${item5.l}-${item5.r}`, className: `device-menu-${item5.l}-${item5.r} ${item5.level ? `level-${item5.level}` : ''}` },
                                                                                        React.createElement(NavLink, { to: '#', className: `sub-item level-${item5.level}`, onClick: () => onClickSubItem(`${item4.l}-${item4.r}`, `${item5.l}-${item5.r}`, example) },
                                                                                            React.createElement("p", null, item5.name),
                                                                                            item5.hasChildren && React.createElement("div", { className: 'chevron sub-chevron' },
                                                                                                React.createElement(IconChevron, null)))));
                                                                                }),
                                                                                React.createElement("span", { className: 'submenu-bar submenu-bar-bottom' }))));
                                                                }),
                                                                React.createElement("span", { className: 'submenu-bar submenu-bar-bottom' }))));
                                                }),
                                                React.createElement("span", { className: 'submenu-bar submenu-bar-bottom' }))));
                                }),
                                React.createElement("span", { className: 'submenu-bar submenu-bar-bottom' }))));
                })))));
};
export default SubMenuTestMenu;
