import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import tinycolor from 'tinycolor2';
import { isMechanicAccount } from '@utils/auth';
const CompanyColor = () => {
    const cookies = new Cookies();
    const mechanicAccount = isMechanicAccount();
    const hex = mechanicAccount ? 'F86F00' : cookies.get('color') ? cookies.get('color') : '008072';
    const hexDarker = tinycolor(hex).darken(5).toHex();
    const hexDarkerMenuLevel1 = tinycolor(hex).darken(15).toHex();
    const hexDarkerMenuLevel2 = tinycolor(hexDarkerMenuLevel1).darken(4).toHex();
    const hexDarkerMenuLevel3 = tinycolor(hexDarkerMenuLevel2).darken(4).toHex();
    const hexDarkerMenuLevel4 = tinycolor(hexDarkerMenuLevel3).darken(4).toHex();
    const hexDarkerMenuLevel5 = tinycolor(hexDarkerMenuLevel4).darken(4).toHex();
    const hexMenuBorderRight = tinycolor(hex).darken(20).toHex();
    const rgb = tinycolor(hex).toRgb();
    useEffect(() => {
        const faviconUpdate = async () => {
            const favicon = document.querySelector("link[rel~='icon']");
            if (hex === '0061BB') {
                favicon.href = require('../../../../src/assets/favicon-blue.ico');
            }
            else if (hex === 'F86F00') {
                favicon.href = require('../../../../src/assets/favicon-orange.ico');
            }
            else if (hex === 'FECC00') {
                favicon.href = require('../../../../src/assets/favicon-yellow.ico');
            }
            else if (hex === 'EC1C25') {
                favicon.href = require('../../../../src/assets/favicon-red.ico');
            }
            else {
                favicon.href = require('../../../../src/assets/favicon.ico');
            }
        };
        faviconUpdate();
    }, [hex]);
    return (React.createElement(React.Fragment, null,
        React.createElement("style", { dangerouslySetInnerHTML: {
                __html: `
          .theme-btn,
          .MuiButton-root.MuiButton-contained,
          ul.parent-menu li a.active,
          ul.company-menu li a.active {
            background: #${hex}!important;
          }

          h1, h2, h3, h4, h5, h6,
          .MuiCustomSwitchBase-root:not(.positive-negative-switch) .MuiSwitch-switchBase.Mui-checked,
          .MuiCustomSwitchBase-root:not(.positive-negative-switch):hover .MuiSwitch-switchBase.Mui-checked {
            color: #${hex}!important;
          }

          .MuiCustomSelectBase-root .MuiChip-root,
          .MuiCustomSwitchBase-root:not(.positive-negative-switch) .Mui-checked+.MuiSwitch-track,
          .MuiCustomSwitchBase-root:not(.positive-negative-switch):hover .Mui-checked+.MuiSwitch-track {
            background-color: #${hex}!important;
          }

          #favicon path:nth-child(2),
          #logo a svg path:nth-child(2),
          #logo a svg path:nth-child(3),
          .loading-container .logo-spinner svg path:nth-child(3),
          .MuiCheckbox-root.Mui-checked svg path, 
          .MuiCheckbox-root.MuiCheckbox-indeterminate svg path
          {
            fill: #${hex}!important;
          }

          div.menu-with-submenu .content-level-1 li.level-1.active, div.menu-without-submenu .content-level-1 li.level-1.active {
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']},1) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']},0) 100%)!important;
          }
          
          div.menu-with-submenu .content-level-2 li, div.menu-without-submenu .content-level-2 li {
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']},.6) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']},0) 100%)!important;
          }

          div.menu-with-submenu .content-level-3 li, div.menu-without-submenu .content-level-3 li {
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']},.4) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']},0) 100%)!important;
          }

          div.menu-with-submenu .content-level-4 li, div.menu-without-submenu .content-level-4 li {
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']},.3) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']},0) 100%)!important;
          }
          
          div.menu-with-submenu .content-level-5 li, div.menu-without-submenu .content-level-5 li {
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']},.2) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']},0) 100%)!important;
          }
          
          div.menu-with-submenu .content-level-1 li.active a 
          {
            border-right: 8px solid rgba(${rgb['r']},${rgb['g']},${rgb['b']},.3)!important;
          }

          div.menu-with-submenu .level-1.last-active a.parent-item,
          div.menu-with-submenu .level-2.last-active a.sub-item.level-2,
          div.menu-with-submenu .level-3.last-active a.sub-item.level-3,
          div.menu-with-submenu .level-4.last-active a.sub-item.level-4,
          div.menu-with-submenu .level-5.last-active a.sub-item.level-5
          {
            border-right: 8px solid #${hex}!important;
          }

          .ui.card.stienen-card .content .header-border,
          .ui.modal.stienen-modal .content .header-border,
          .stienen-tabs .header-border,
          .ui.table.stienen-table tr.header-border,
          .ui.table.stienen-table.selectable tr.header-border:hover,
          .stienen-table.MuiBox-root .header-border,
          .MuiCard-root.stienen-card .header-border 
          {
            background: rgb(${rgb['r']},${rgb['g']},${rgb['b']})!important;
            background: linear-gradient(90deg, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 0) 0%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 1) 15%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 1) 50%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 1) 85%, rgba(${rgb['r']},${rgb['g']},${rgb['b']}, 0) 100%)!important;
          }

          .ui.selection.dropdown .ui.label {
            background: #${hex}!important;
          }

          .ui.selection.dropdown .ui.label:hover {
            background: #${hexDarker}!important;
          }

          .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
          .rs-calendar-table-cell:hover .rs-calendar-table-cell-content,
          .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content,
          .rs-btn-primary {
            background: #${hex} !important;
          }

          .rs-calendar-table-cell.rs-calendar-table-cell-in-range::before {
            background: rgba(${rgb['r']},${rgb['g']},${rgb['b']}, .5) !important;
          }

          .theme-btn:hover,
          .MuiButton-root.MuiButton-contained:hover,
          .rs-btn-primary:hover {
            background: #${hexDarker} !important;
          }

          .menu-example-2,
          .menu-example-3 {
            div.menu-with-submenu .content-level-1 li.level-1.active { background: #${hexDarkerMenuLevel1}!important; }
            div.menu-with-submenu .content-level-2 li { background: #${hexDarkerMenuLevel2}!important; }
            div.menu-with-submenu .content-level-3 li { background: #${hexDarkerMenuLevel3}!important; }
            div.menu-with-submenu .content-level-4 li { background: #${hexDarkerMenuLevel4}!important; }
            div.menu-with-submenu .content-level-5 li { background: #${hexDarkerMenuLevel5}!important; }
            
            div.menu-with-submenu .content-level-1 li.active a 
            {
              border-right: 8px solid #${hexMenuBorderRight}!important;
            }

            div.menu-with-submenu .level-1.last-active a.parent-item,
            div.menu-with-submenu .level-2.last-active a.sub-item.level-2,
            div.menu-with-submenu .level-3.last-active a.sub-item.level-3,
            div.menu-with-submenu .level-4.last-active a.sub-item.level-4,
            div.menu-with-submenu .level-5.last-active a.sub-item.level-5
            {
              border-right: 8px solid #${hex}!important;
            }
          }

          
          
        `,
            } })));
};
export default CompanyColor;
