import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import IconHome from '!svg-react-loader?name=IconHome!assets/svg/icon-barn2.svg';
const BreadCrumbs = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [pagePath, setPagePath] = useState('');
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const tabs = useSelector((state) => state && state.widgets && state.widgets.tabs);
    useEffect(() => {
        location.pathname && setPagePath(location.pathname);
        history.listen((location) => {
            location.pathname && setPagePath(location.pathname);
        });
    }, [history]);
    const pathArray = pagePath.split('/');
    const pathArrayWithoutEmpty = pathArray.filter((elm) => elm);
    if (pathArrayWithoutEmpty[0] === 'locations' && pathArrayWithoutEmpty[1]) {
        const locationId = pathArrayWithoutEmpty[1];
        const findLocation = locations.find((location) => location.id === locationId);
        findLocation ? (pathArrayWithoutEmpty[1] = findLocation.name) : pathArrayWithoutEmpty[1];
        if (pathArrayWithoutEmpty[2]) {
            const deviceId = pathArrayWithoutEmpty[2];
            const findDevice = findLocation && findLocation.devices.find((device) => device.id === deviceId);
            findDevice ? (pathArrayWithoutEmpty[2] = findDevice.name) : pathArrayWithoutEmpty[2];
        }
    }
    if (pathArrayWithoutEmpty[0] === 'event-log' && pathArrayWithoutEmpty[1] || pathArrayWithoutEmpty[0] === 'data-log' && pathArrayWithoutEmpty[1]) {
        const locationId = pathArrayWithoutEmpty[1];
        const findLocation = locations.find((location) => location.id === locationId);
        findLocation ? (pathArrayWithoutEmpty[1] = findLocation.name) : pathArrayWithoutEmpty[1];
        if (pathArrayWithoutEmpty[2]) {
            const deviceId = pathArrayWithoutEmpty[2];
            const findDevice = findLocation && findLocation.devices.find((device) => device.id === deviceId);
            findDevice ? (pathArrayWithoutEmpty[2] = findDevice.name) : pathArrayWithoutEmpty[2];
        }
    }
    if (pathArrayWithoutEmpty[0] === 'widgets' && pathArrayWithoutEmpty[1]) {
        const tabId = pathArrayWithoutEmpty[1];
        const findTab = tabs.find((tab) => tab.uuid === tabId);
        findTab ? (pathArrayWithoutEmpty[1] = findTab.name) : pathArrayWithoutEmpty[1];
    }
    return (React.createElement("div", { className: 'breadcrumbs' },
        React.createElement("div", { className: 'home-item' },
            React.createElement(Link, { to: '/dashboard' },
                React.createElement(IconHome, null))),
        pathArrayWithoutEmpty &&
            pathArrayWithoutEmpty.map((item, key) => {
                return (React.createElement("div", { className: `item ${key === 0 && 'first-item'}`, key: key },
                    React.createElement("span", null, "/"),
                    React.createElement("h1", null,
                        React.createElement(Link, { to: key === 0 ? `/${pathArray[1]}`
                                : key === 1 ? `/${pathArray[1]}/${pathArray[2]}`
                                    : key === 2 ? `/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}`
                                        : key === 3 ? `/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}/${pathArray[4]}`
                                            : key === 4 ? `/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}/${pathArray[4]}/${pathArray[5]}`
                                                : '' }, item === 'select-customer'
                            ? t('main.customers')
                            : item === 'dashboard'
                                ? t('main.dashboard')
                                : item === 'alarms'
                                    ? t('main.alarms')
                                    : item === 'widgets'
                                        ? t('main.widgets')
                                        : item === 'locations'
                                            ? t('main.locations')
                                            : item === 'admin'
                                                ? t('main.admin')
                                                : item === 'users'
                                                    ? t('main.users')
                                                    : item === 'subscription'
                                                        ? t('main.subscription')
                                                        : item === 'add-location'
                                                            ? t('main.addLocation')
                                                            : item === 'dealers'
                                                                ? t('main.dealers')
                                                                : item === 'dealer-login'
                                                                    ? t('main.dealerLogin')
                                                                    : item === 'settings'
                                                                        ? t('main.settings')
                                                                        : item === 'event-log'
                                                                            ? t('main.eventLog')
                                                                            : item === 'remote'
                                                                                ? t('main.remote')
                                                                                : item === 'device'
                                                                                    ? t('main.device')
                                                                                    : item === 'status'
                                                                                        ? t('main.status')
                                                                                        : item === 'statistics'
                                                                                            ? t('main.statistics')
                                                                                            : item === 'logs'
                                                                                                ? t('main.logs')
                                                                                                : item))));
            })));
};
export default BreadCrumbs;
