import { isCustomerAccount } from '@utils/auth';
import { onClickItem } from '@utils/menu';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
const SubMenuAdmin = () => {
    const { t } = useTranslation();
    const isCustomer = isCustomerAccount();
    useEffect(() => {
        const pagePath = location.pathname;
        const pathArray = pagePath.split('/');
        const pathArrayWithoutEmpty = pathArray.filter((elm) => elm);
        if (pathArrayWithoutEmpty[0] === 'admin' && pathArrayWithoutEmpty[1]) {
            const page = pathArrayWithoutEmpty[1];
            onClickItem(page, 1);
        }
    }, [history]);
    return (React.createElement("div", { className: 'sidebar-menu' },
        React.createElement("div", { className: 'device-menu sub-menu', id: 'device-nav-example-1' },
            React.createElement("div", { className: 'sub-menu-header' }, t('main.admin')),
            React.createElement("div", { className: 'menu-with-submenu' },
                React.createElement("ul", { id: "device-nav", className: 'example-1 content-level-1' },
                    React.createElement("li", { id: 'device-menu-users', className: 'device-menu-users level-1' },
                        React.createElement(NavLink, { onClick: () => onClickItem('users', 1), className: 'parent-item', to: '/admin/users' },
                            React.createElement("p", null, t('main.users')))),
                    React.createElement("li", { id: 'device-menu-dealers', className: 'device-menu-dealers level-1' },
                        React.createElement(NavLink, { onClick: () => onClickItem('dealers', 1), className: 'parent-item', to: '/admin/dealers' },
                            React.createElement("p", null, t('main.dealers')))),
                    React.createElement("li", { id: 'device-menu-subscription', className: 'device-menu-subscription level-1' },
                        React.createElement(NavLink, { onClick: () => onClickItem('subscription', 1), className: 'parent-item', to: '/admin/subscription' },
                            React.createElement("p", null, t('main.subscription')))),
                    isCustomer &&
                        React.createElement("li", { id: 'device-menu-add-location', className: 'device-menu-add-location level-1' },
                            React.createElement(NavLink, { onClick: () => onClickItem('add-location', 1), className: 'parent-item', to: '/admin/add-location' },
                                React.createElement("p", null, t('main.addLocation')))))))));
};
export default SubMenuAdmin;
