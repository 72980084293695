import React, { useEffect, useState } from 'react';
import { toastrSuccess } from '@elementComponents/Toastr';
import { Box, Modal } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { logoutSuccess } from '../../state/slice';
import { clearCustomersSuccess, clearCustomerSuccess } from 'app/customers/state/slice';
import { clearLocationsSuccess } from 'app/locations/state/slice';
import { clearDealerInvitesSuccess } from 'app/dealer/state/slice';
import { clearEventLogSuccess } from 'app/locations/components/Logs/eventLog/state/slice';
import { clearMetricsSuccess, clearProductsSuccess } from 'app/admin/subscription/state/slice';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isDealerAccount, isServiceAccount } from '@utils/auth';
import LicenseModalTerms from './LicenseModalTerms';
import LicenseModalWizard from './LicenseModalWizard';
const LicenseModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const serviceAccount = isServiceAccount();
    const dealerAccount = isDealerAccount();
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const user = useSelector((state) => state.users && state.users.user);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const locationsIsLoaded = useSelector((state) => state.locations && state.locations.list && state.locations.list.isLoaded);
    const pathname = location.pathname;
    const hasLocations = locations && locations.length > 0;
    const hasDevices = locations && locations[0] && locations[0].devices && locations[0].devices.length > 0;
    useEffect(() => {
        const hasNotAcceptedLicense = user
            && (user.email !== ''
                && user.hasAcceptedLicence === false) ? true : false;
        if (serviceAccount) {
            setOpen(false);
        }
        else if (locationsIsLoaded && dealerAccount && hasNotAcceptedLicense === true) {
            setOpen(true);
            setActiveStep(0);
        }
        else if (locationsIsLoaded && !dealerAccount && user && hasNotAcceptedLicense === true) {
            setOpen(true);
            setActiveStep(0);
        }
        else if (locationsIsLoaded && !dealerAccount && user && locations && !hasLocations) {
            setOpen(true);
            setActiveStep(1);
        }
        else if (locationsIsLoaded && !dealerAccount && user && locations && hasLocations && !hasDevices) {
            setOpen(true);
            setActiveStep(2);
        }
        else {
            setOpen(false);
        }
    }, [pathname, hasLocations, user, locationsIsLoaded]);
    const onCloseModal = (_event, reason) => {
        if (reason && reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        setOpen(false);
    };
    const onClickLogout = async () => {
        Auth.signOut()
            .then(() => {
            toastrSuccess(t('auth.logoutMessageHeader'), t('auth.logoutMessageText'));
            dispatch(logoutSuccess());
            dispatch(clearCustomersSuccess());
            dispatch(clearCustomerSuccess());
            dispatch(clearLocationsSuccess());
            dispatch(clearDealerInvitesSuccess());
            dispatch(clearEventLogSuccess());
            dispatch(clearMetricsSuccess());
            dispatch(clearProductsSuccess());
            onCloseModal('', 'closeButtonClick');
            history.push('/login');
        })
            .catch((err) => console.log(err));
    };
    return open && (React.createElement(Modal, { disableAutoFocus: true, open: open, className: 'stienen-modal' },
        React.createElement(Box, null,
            React.createElement("h2", { className: 'modal-title' }, t('auth.welcomeToFarmConnect')),
            React.createElement("div", { className: 'modal-content' }, hasLocations && hasDevices
                ? React.createElement(LicenseModalTerms, { onCloseModal: onCloseModal, onClickLogout: onClickLogout })
                : React.createElement(LicenseModalWizard, { onCloseModal: onCloseModal, onClickLogout: onClickLogout, activeStep: activeStep, setActiveStep: setActiveStep })))));
};
export default LicenseModal;
