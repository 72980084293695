export const getDeviceById = (deviceId, locations) => {
    const devices = locations &&
        locations.map((location) => {
            return location.devices && location.devices.find((device) => device.id === deviceId);
        });
    const filteredDevice = devices.filter((elm) => elm);
    return filteredDevice && filteredDevice[0];
};
export const testDeviceMenu = [
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 1,
        'Name': 'Overzicht',
        'Prefix': null,
        'R': 2,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b7b8c5b2-842b-44b1-9cde-67128934d2fd',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 3,
        'Name': 'Ventilatie',
        'Prefix': null,
        'R': 96,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 4,
        'Name': 'Afdelingsventilatie',
        'Prefix': 'R1_',
        'R': 29,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '4830d860-16a7-46fd-94bd-b632377c7a2c',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 5,
        'Name': 'Opties',
        'Prefix': 'R1_',
        'R': 8,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'c73a8755-3129-453e-9bcc-1bc545bac8cd',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 6,
        'Name': 'Vorstbeveiliging',
        'Prefix': 'R1_',
        'R': 7,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd048123c-19a3-4d3b-a6a2-a56625b41715',
        'Version': 220,
        'Visible': 'and nz $R1_OVORST nz $BT_TYPE_KOPIEA ne $APP_SPEC_KOPIEA 6'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 9,
        'Name': 'Compensaties',
        'Prefix': 'R1_',
        'R': 24,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': 'or nz $AFD_ONACHTCOMP nz $AFD_OTEMPCOMP [ or and nz $AFD_OBANDCOMP nz $BT_TYPE_KOPIEA and nz $AFD_OMINCOMP nz $BT_TYPE_KOPIEA ] [ and nz $R12_TYPE nz $R12_ORVCOMP ] [ and nz $CO2_TYPE nz $CO2_OCOMP ]'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 10,
        'Name': 'Nachtinstelling',
        'Prefix': null,
        'R': 11,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '6444d18d-4fbc-4e78-a89a-b60ed703fe14',
        'Version': 220,
        'Visible': 'nz $AFD_ONACHTCOMP'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 12,
        'Name': 'Afdelingstemperatuur',
        'Prefix': null,
        'R': 13,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b9bdf5e0-ca5d-4a0e-9acd-a6083a14fb70',
        'Version': 220,
        'Visible': 'nz $AFD_OTEMPCOMP'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 14,
        'Name': 'Afdelingsventilatie',
        'Prefix': null,
        'R': 15,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ee785862-6ffe-48ed-8fd4-c7396368dd79',
        'Version': 220,
        'Visible': 'nz $AFD_OVENTCOMP'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 16,
        'Name': 'RV_Compensatie',
        'Prefix': 'R12_',
        'R': 19,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '408be566-a94b-4d96-a0c6-1d765e45176a',
        'Version': 220,
        'Visible': 'nz $R12_ORVCOMP'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 17,
        'Name': 'Historie',
        'Prefix': null,
        'R': 18,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '87ea48b7-1894-4dcd-82c3-b8b4ab6460be',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 20,
        'Name': 'CO2_Compensatie',
        'Prefix': 'CO2_',
        'R': 23,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '713d6ee9-ce7c-4713-8277-849bc25bf062',
        'Version': 220,
        'Visible': 'nz $CO2_OCOMP'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 21,
        'Name': 'Historie',
        'Prefix': null,
        'R': 22,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1f22a2df-bb57-42f1-933f-f0665324882f',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 25,
        'Name': 'Handbediening',
        'Prefix': 'R1_',
        'R': 26,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'dd22d236-0506-4d2c-998b-0f1ca55e0151',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 27,
        'Name': 'Historie',
        'Prefix': null,
        'R': 28,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ca5b309d-5240-42db-8b0d-c06d82669bd7',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 30,
        'Name': 'Diafragmaklep',
        'Prefix': 'R3_',
        'R': 31,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '2cb624b3-e3e9-4dec-b6a9-a871cea1e4d0',
        'Version': 220,
        'Visible': 'and nz $R3_KEUZE ze $R1_MEETVENT1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 32,
        'Name': 'Mengventilator',
        'Prefix': 'R14_',
        'R': 35,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '19056ae1-2e53-4595-9672-1a8d9246c09c',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 33,
        'Name': 'Historie',
        'Prefix': null,
        'R': 34,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '326e45f7-9186-4251-8ca0-0cfc8e062e73',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 36,
        'Name': 'Bypassklep',
        'Prefix': 'R4_',
        'R': 39,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '0636cb65-0653-4260-ac91-ef8138c05324',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 37,
        'Name': 'Historie',
        'Prefix': null,
        'R': 38,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'bc120eaa-8aa9-4ac4-8a06-ee52117a8ecd',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 40,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 43,
        'Reference': null,
        'Replaces': 'G5_m_,G5_M_,G5_T_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '78ddbc0a-00d1-4704-9cdb-1fc12942b52e',
        'Version': 220,
        'Visible': 'eq $#KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 41,
        'Name': 'Historie',
        'Prefix': null,
        'R': 42,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'c34f658a-7a67-4724-a0db-98ed58e5a2f9',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 44,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 47,
        'Reference': null,
        'Replaces': 'G5_m_,G5_M_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '39598e33-537d-4506-bf34-e7f5d5ae36ed',
        'Version': 220,
        'Visible': 'eq $#KEUZE 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 45,
        'Name': 'Historie',
        'Prefix': null,
        'R': 46,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd57ee7f5-e690-4e55-ac55-825a026649ec',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 48,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 53,
        'Reference': null,
        'Replaces': 'G5_m_,G5_M_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'db29e025-608e-45d8-a539-c0565666b1c6',
        'Version': 220,
        'Visible': 'eq $#KEUZE 3'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 49,
        'Name': 'Historie',
        'Prefix': null,
        'R': 50,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e58b13d3-04da-4bae-82e2-f5c1e8a367cd',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 51,
        'Name': 'CompensatieDruk',
        'Prefix': 'R5_',
        'R': 52,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '075a7780-1757-42bb-8e50-00dacb028123',
        'Version': 220,
        'Visible': 'or [ and [ or eq $#TYPE $REG_KLEPD eq $#TYPE $REG_KLEPMCA ] [ and nz $#COMPDRUK nz $BT_TYPE_KOPIEA ] ] [ and eq $#TYPE $REG_KLEPMCA eq $#MCA_INST 2 ]'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 54,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_,G5_m_,G5_M_',
        'R': 67,
        'Reference': null,
        'Replaces': 'G5_m_,G5_M_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e275193b-4a82-4604-854c-d924ff329c79',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 55,
        'Name': 'MCAklep',
        'Prefix': 'R5_',
        'R': 56,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '267f7e20-1c6b-42aa-9262-edfabe4d5478',
        'Version': 220,
        'Visible': 'eq $#MCA_INST 0'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 57,
        'Name': 'MCAklep',
        'Prefix': 'R5_',
        'R': 58,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '8a0a2338-18fd-497f-a0c0-e6d0179ef601',
        'Version': 220,
        'Visible': 'eq $#MCA_INST 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 59,
        'Name': 'CompensatieDruk',
        'Prefix': 'R5_',
        'R': 60,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '075a7780-1757-42bb-8e50-00dacb028123',
        'Version': 220,
        'Visible': 'or [ and [ or eq $#TYPE $REG_KLEPD eq $#TYPE $REG_KLEPMCA ] [ and nz $#COMPDRUK nz $BT_TYPE_KOPIEA ] ] [ and eq $#TYPE $REG_KLEPMCA eq $#MCA_INST 2 ]'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 61,
        'Name': 'Historie',
        'Prefix': 'R5_',
        'R': 62,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '04305154-fa8e-4186-8814-a08bd786fbe8',
        'Version': 220,
        'Visible': 'eq $R5_MCA_INST 0'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 63,
        'Name': 'Historie',
        'Prefix': 'R5_',
        'R': 64,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '66c3c749-9896-4864-8c33-ff5810954a4f',
        'Version': 220,
        'Visible': 'eq $R5_MCA_INST 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 65,
        'Name': 'Historie',
        'Prefix': 'R5_',
        'R': 66,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ea10547a-05e4-4de1-97e5-97147d63f7ce',
        'Version': 220,
        'Visible': 'eq $R5_MCA_INST 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 68,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 71,
        'Reference': null,
        'Replaces': 'G6_m_,G6_M_,G6_T_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '78ddbc0a-00d1-4704-9cdb-1fc12942b52e',
        'Version': 220,
        'Visible': 'eq $#KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 69,
        'Name': 'Historie',
        'Prefix': null,
        'R': 70,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f5fa9cb6-4c38-45e0-a020-286882784b6e',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 72,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 75,
        'Reference': null,
        'Replaces': 'G6_m_,G6_M_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '39598e33-537d-4506-bf34-e7f5d5ae36ed',
        'Version': 220,
        'Visible': 'eq $#KEUZE 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 73,
        'Name': 'Historie',
        'Prefix': null,
        'R': 74,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ee8644b2-d5d2-4b44-85cf-7470d44294f2',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 76,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 81,
        'Reference': null,
        'Replaces': 'G6_m_,G6_M_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'db29e025-608e-45d8-a539-c0565666b1c6',
        'Version': 220,
        'Visible': 'eq $#KEUZE 3'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 77,
        'Name': 'Historie',
        'Prefix': null,
        'R': 78,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ade7c0cc-eced-42cd-8160-8950fd415434',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 79,
        'Name': 'CompensatieDruk',
        'Prefix': 'R6_',
        'R': 80,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '075a7780-1757-42bb-8e50-00dacb028123',
        'Version': 220,
        'Visible': 'or [ and [ or eq $#TYPE $REG_KLEPD eq $#TYPE $REG_KLEPMCA ] [ and nz $#COMPDRUK nz $BT_TYPE_KOPIEA ] ] [ and eq $#TYPE $REG_KLEPMCA eq $#MCA_INST 2 ]'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 82,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_,G6_m_,G6_M_',
        'R': 95,
        'Reference': null,
        'Replaces': 'G6_m_,G6_M_',
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e275193b-4a82-4604-854c-d924ff329c79',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 83,
        'Name': 'MCAklep',
        'Prefix': 'R6_',
        'R': 84,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '267f7e20-1c6b-42aa-9262-edfabe4d5478',
        'Version': 220,
        'Visible': 'eq $#MCA_INST 0'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 85,
        'Name': 'MCAklep',
        'Prefix': 'R6_',
        'R': 86,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '8a0a2338-18fd-497f-a0c0-e6d0179ef601',
        'Version': 220,
        'Visible': 'eq $#MCA_INST 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 87,
        'Name': 'CompensatieDruk',
        'Prefix': 'R6_',
        'R': 88,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '075a7780-1757-42bb-8e50-00dacb028123',
        'Version': 220,
        'Visible': 'or [ and [ or eq $#TYPE $REG_KLEPD eq $#TYPE $REG_KLEPMCA ] [ and nz $#COMPDRUK nz $BT_TYPE_KOPIEA ] ] [ and eq $#TYPE $REG_KLEPMCA eq $#MCA_INST 2 ]'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 89,
        'Name': 'Historie',
        'Prefix': 'R6_',
        'R': 90,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1d2bfa26-a58d-4c83-8bc0-267737214cc4',
        'Version': 220,
        'Visible': 'eq $R5_MCA_INST 0'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 91,
        'Name': 'Historie',
        'Prefix': 'R6_',
        'R': 92,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ea5dde5c-c960-4228-9ee2-8f037004b100',
        'Version': 220,
        'Visible': 'eq $R5_MCA_INST 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 93,
        'Name': 'Historie',
        'Prefix': 'R6_',
        'R': 94,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '0817b3f6-a460-4137-a02a-e8e4aaacfd8c',
        'Version': 220,
        'Visible': 'eq $R5_MCA_INST 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 97,
        'Name': 'Verwarming',
        'Prefix': null,
        'R': 132,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 98,
        'Name': 'Afdelingsverwarming',
        'Prefix': 'R7_',
        'R': 105,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e7d1f4d3-ee4b-4519-b36e-1e49138a01cb',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 99,
        'Name': 'Bedrijfsuren',
        'Prefix': 'R7_',
        'R': 102,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'aeb2eb68-3907-4af7-bca0-0e7bce4c38f5',
        'Version': 220,
        'Visible': 'and nz $#KEUZE ( $EXPR_RELSSR ) $#UITNR2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 100,
        'Name': 'Historie',
        'Prefix': null,
        'R': 101,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '95120331-a96f-4b31-bfe1-885e9f6dfdaf',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 103,
        'Name': 'Historie',
        'Prefix': null,
        'R': 104,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b4faecf3-20b6-44f7-9045-3ecfd040bae7',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 106,
        'Name': 'Inlaatverwarming',
        'Prefix': 'R8_',
        'R': 113,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9314507b-7964-4b37-b77c-f6e8e763542e',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 107,
        'Name': 'Bedrijfsuren',
        'Prefix': 'R8_',
        'R': 110,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'aeb2eb68-3907-4af7-bca0-0e7bce4c38f5',
        'Version': 220,
        'Visible': 'and nz $#KEUZE ( $EXPR_RELSSR ) $#UITNR2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 108,
        'Name': 'Historie',
        'Prefix': null,
        'R': 109,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd1c779bf-e45c-4101-9f5d-3495966e9a7e',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 111,
        'Name': 'Historie',
        'Prefix': null,
        'R': 112,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '00ff854e-0afe-4674-9cb4-754b58141a08',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 114,
        'Name': 'Vloerverwarming',
        'Prefix': 'R9_',
        'R': 123,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b2826d2d-5077-4ce0-b810-a23bcfdc083e',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 115,
        'Name': 'Bedrijfsuren',
        'Prefix': 'R9_',
        'R': 118,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'aeb2eb68-3907-4af7-bca0-0e7bce4c38f5',
        'Version': 220,
        'Visible': 'and nz $#KEUZE ( $EXPR_RELSSR ) $#UITNR2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 116,
        'Name': 'Historie',
        'Prefix': null,
        'R': 117,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '35db9660-c821-43af-ba44-5fe0d32be6dc',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 119,
        'Name': 'Opties',
        'Prefix': 'R9_',
        'R': 120,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '5e31b012-f53d-451a-ac5a-ec88e1c92ec5',
        'Version': 220,
        'Visible': 'or nz $AFD_OVLOERCOMP nz $#MAX_AANVOER'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 121,
        'Name': 'Historie',
        'Prefix': null,
        'R': 122,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '7ed48929-540d-4518-936d-f78e3977b8bf',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 124,
        'Name': 'Nestverwarming',
        'Prefix': 'R10_',
        'R': 131,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '188bfa17-7e4d-4722-8535-b5cf9263ade7',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 125,
        'Name': 'Bedrijfsuren',
        'Prefix': 'R10_',
        'R': 128,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '6d6482b4-73dc-492b-88af-b47c48f093b7',
        'Version': 220,
        'Visible': 'and nz $#KEUZE ( $EXPR_RELSSR ) $#UITNR2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 126,
        'Name': 'Historie',
        'Prefix': null,
        'R': 127,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '0d4e90d9-051d-4702-bea9-12c7b8d77542',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 129,
        'Name': 'Historie',
        'Prefix': null,
        'R': 130,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9efe091d-9a0e-4772-948d-3beb11a08934',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 133,
        'Name': 'Overige',
        'Prefix': null,
        'R': 174,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': 'or nz $CO2_KEUZE nz $R11_KEUZE nz $R12_KEUZE nz $R13_KEUZE nz $R15_KEUZE nz $R17_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 134,
        'Name': 'Koeling',
        'Prefix': 'R11_',
        'R': 141,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '37bcd1cb-e3ac-4abd-b9cd-abafa082c4df',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 135,
        'Name': 'Inweken',
        'Prefix': 'R11_',
        'R': 136,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '17da00ec-13ee-4e36-84da-ae67cec3a49b',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 137,
        'Name': 'Opties',
        'Prefix': 'R11_',
        'R': 138,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '245c9581-b118-4fbf-a8b0-4966301aa8d0',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 139,
        'Name': 'Historie',
        'Prefix': null,
        'R': 140,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '11c4f468-44b5-414a-8925-6bfb41421263',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 142,
        'Name': 'Bevochtiging',
        'Prefix': 'R12_',
        'R': 143,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '90cba7e8-f2e1-4a1e-8dbe-d72576826b83',
        'Version': 220,
        'Visible': 'and nz $#TYPE ( $EXPR_RELSSR ) $#UITNR2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 144,
        'Name': 'RV',
        'Prefix': 'R12_',
        'R': 147,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9b16f873-d80d-4856-99d9-1dd7f8815d30',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 145,
        'Name': 'Historie',
        'Prefix': null,
        'R': 146,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '87ea48b7-1894-4dcd-82c3-b8b4ab6460be',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 148,
        'Name': 'CO2',
        'Prefix': 'CO2_',
        'R': 151,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'cf9974bc-d2d1-4c71-8407-0eb8393a1182',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 149,
        'Name': 'Historie',
        'Prefix': null,
        'R': 150,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1f22a2df-bb57-42f1-933f-f0665324882f',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 152,
        'Name': 'Schakelklok',
        'Prefix': 'R13_',
        'R': 153,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b3183314-9a94-4ee6-a76f-27524b047a83',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 154,
        'Name': 'Waterteller',
        'Prefix': 'R15_',
        'R': 157,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '72861d83-b5ac-41c3-8c3f-b982142b88e8',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 155,
        'Name': 'Historie',
        'Prefix': null,
        'R': 156,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b50a8c5d-3ea3-4e66-a242-33e75b1b9eb6',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 158,
        'Name': 'TemperatuurBewaking',
        'Prefix': 'R17_',
        'R': 161,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '7c82d4d2-9cb0-4e43-b324-cbd18b8f4211',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 159,
        'Name': 'Historie',
        'Prefix': null,
        'R': 160,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '216ffa2c-e431-4aa0-95fd-d8bf501fa8b4',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 162,
        'Name': 'Voelers',
        'Prefix': null,
        'R': 173,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '43d14a9b-a500-433f-9cd6-048ea58608c4',
        'Version': 220,
        'Visible': 'nz $LOG_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 163,
        'Name': 'Voeler_n,1',
        'Prefix': 'LOG1_',
        'R': 164,
        'Reference': 'Min1_Temp0',
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 165,
        'Name': 'Voeler_n,2',
        'Prefix': 'LOG2_',
        'R': 166,
        'Reference': 'Min2_Temp0',
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 167,
        'Name': 'Voeler_n,3',
        'Prefix': 'LOG3_',
        'R': 168,
        'Reference': 'Min3_Temp0',
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 3'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 169,
        'Name': 'Voeler_n,4',
        'Prefix': 'LOG4_',
        'R': 170,
        'Reference': 'Min4_Temp0',
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 171,
        'Name': 'Historie',
        'Prefix': null,
        'R': 172,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1e9bd583-07bc-411f-82fd-d2aea0aa1eb0',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 175,
        'Name': 'Groeicurven',
        'Prefix': null,
        'R': 224,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '4e2c338a-b79e-47ca-90f9-ec4b93238ddc',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 176,
        'Name': 'Afdelingsventilatie',
        'Prefix': null,
        'R': 185,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 177,
        'Name': 'Afdelingstemperatuur',
        'Prefix': 'G1_T_',
        'R': 178,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 179,
        'Name': 'MinimumVentilatie',
        'Prefix': 'G1_m_',
        'R': 180,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'fac76b99-70d4-42fa-a9ae-33b7eacdf1eb',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 181,
        'Name': 'MaximumVentilatie',
        'Prefix': 'G1_M_',
        'R': 182,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1538a0f5-e257-4b84-a0aa-a69de23788b2',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 183,
        'Name': 'Diergewicht',
        'Prefix': 'G1_W_',
        'R': 184,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '611ea919-01ad-45c4-b96d-eb8b44da656b',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 186,
        'Name': 'Mengventilator',
        'Prefix': 'G14_T_',
        'R': 187,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'nz $R14_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 188,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 195,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 189,
        'Name': 'Temperatuur',
        'Prefix': 'G5_T_',
        'R': 190,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'eq $R5_KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 191,
        'Name': 'MinimaleKlepopening',
        'Prefix': 'G5_m_',
        'R': 192,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'fac76b99-70d4-42fa-a9ae-33b7eacdf1eb',
        'Version': 220,
        'Visible': 'nz $R5_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 193,
        'Name': 'MaximaleKlepopening',
        'Prefix': 'G5_M_',
        'R': 194,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1538a0f5-e257-4b84-a0aa-a69de23788b2',
        'Version': 220,
        'Visible': 'nz $R5_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 196,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 203,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 197,
        'Name': 'Temperatuur',
        'Prefix': 'G6_T_',
        'R': 198,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'eq $R6_KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 199,
        'Name': 'MinimaleKlepopening',
        'Prefix': 'G6_m_',
        'R': 200,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'fac76b99-70d4-42fa-a9ae-33b7eacdf1eb',
        'Version': 220,
        'Visible': 'nz $R6_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 201,
        'Name': 'MaximaleKlepopening',
        'Prefix': 'G6_M_',
        'R': 202,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1538a0f5-e257-4b84-a0aa-a69de23788b2',
        'Version': 220,
        'Visible': 'nz $R6_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 204,
        'Name': 'Verwarmingen',
        'Prefix': null,
        'R': 213,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 205,
        'Name': 'Afdelingsverwarming',
        'Prefix': 'G7_T_',
        'R': 206,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'nz $R7_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 207,
        'Name': 'Inlaatverwarming',
        'Prefix': 'G8_T_',
        'R': 208,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'nz $R8_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 209,
        'Name': 'Vloerverwarming',
        'Prefix': 'G9_T_',
        'R': 210,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'nz $R9_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 211,
        'Name': 'Nestverwarming',
        'Prefix': 'G10_T_',
        'R': 212,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'nz $R10_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 214,
        'Name': 'Koeling',
        'Prefix': 'R11_',
        'R': 221,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 215,
        'Name': 'Temperatuur',
        'Prefix': 'G11_T_',
        'R': 216,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f32c3594-8488-4652-845b-797e9cb41e59',
        'Version': 220,
        'Visible': 'nz $R11_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 217,
        'Name': 'MinimumKoeling',
        'Prefix': 'G11_m_',
        'R': 218,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'fac76b99-70d4-42fa-a9ae-33b7eacdf1eb',
        'Version': 220,
        'Visible': '( $EXPR_KLEM ) $R11_UITNR1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 219,
        'Name': 'MaximumKoeling',
        'Prefix': 'G11_M_',
        'R': 220,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1538a0f5-e257-4b84-a0aa-a69de23788b2',
        'Version': 220,
        'Visible': '( $EXPR_KLEM ) $R11_UITNR1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 222,
        'Name': 'RV_Compensatie',
        'Prefix': 'G12_R_',
        'R': 223,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '627f1527-da9a-4ecb-95ee-a644b1aaded8',
        'Version': 220,
        'Visible': 'and nz $R12_KEUZE nz $R12_ORVCOMP'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 225,
        'Name': 'Overzichten',
        'Prefix': null,
        'R': 238,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'f18d3682-c1c9-4ef0-b10d-b19f6bfa4568',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 226,
        'Name': 'Afdelingstemperatuur',
        'Prefix': 'R1_,Min_Temp,Min_Tijd,Max_Temp,Max_Tijd',
        'R': 227,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 228,
        'Name': 'Voelers',
        'Prefix': null,
        'R': 237,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '43d14a9b-a500-433f-9cd6-048ea58608c4',
        'Version': 220,
        'Visible': 'nz $LOG_KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 229,
        'Name': 'Voeler_n,1',
        'Prefix': 'LOG1_,Min1_Temp,Min1_Tijd,Max1_Temp,Max1_Tijd',
        'R': 230,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 231,
        'Name': 'Voeler_n,2',
        'Prefix': 'LOG2_,Min2_Temp,Min2_Tijd,Max2_Temp,Max2_Tijd',
        'R': 232,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 233,
        'Name': 'Voeler_n,3',
        'Prefix': 'LOG3_,Min3_Temp,Min3_Tijd,Max3_Temp,Max3_Tijd',
        'R': 234,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 3'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 235,
        'Name': 'Voeler_n,4',
        'Prefix': 'LOG4_,Min4_Temp,Min4_Tijd,Max4_Temp,Max4_Tijd',
        'R': 236,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '0eac4252-0d39-45fb-b8cf-483ada8bf1d5',
        'Version': 220,
        'Visible': 'ge $LOG_KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 239,
        'Name': 'Alarm',
        'Prefix': null,
        'R': 282,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e9a13359-8b1f-4855-a4d3-484f0000706b',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 240,
        'Name': 'Afdelingstemperatuur',
        'Prefix': 'R1_',
        'R': 241,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '18172eb7-965e-4f18-bc8e-a0280ecb909a',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 242,
        'Name': 'Afdelingsventilatie',
        'Prefix': 'R1_',
        'R': 243,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'c40598ba-3b9c-4a07-9bce-b664346cd6d7',
        'Version': 220,
        'Visible': 'nz $#MEETVENT1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 244,
        'Name': 'Mengventilator',
        'Prefix': 'R14_',
        'R': 245,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '61e770b8-1d44-42d8-be00-18f919ecdbbb',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 246,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 247,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b8e8f5c4-a09d-4d07-9f56-992157d24c98',
        'Version': 220,
        'Visible': 'eq $#KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 248,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 249,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e09e2271-31a3-4a50-82e5-7cafe11f453f',
        'Version': 220,
        'Visible': '0'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 250,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 251,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ca2bc3fb-3aeb-4a9b-903c-8454acaec6db',
        'Version': 220,
        'Visible': 'eq $#KEUZE 3'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 252,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 253,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '140e97de-b79b-4f1f-a0d5-b27654393bf3',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 254,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 255,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b8e8f5c4-a09d-4d07-9f56-992157d24c98',
        'Version': 220,
        'Visible': 'eq $#KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 256,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 257,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e09e2271-31a3-4a50-82e5-7cafe11f453f',
        'Version': 220,
        'Visible': '0'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 258,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 259,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ca2bc3fb-3aeb-4a9b-903c-8454acaec6db',
        'Version': 220,
        'Visible': 'eq $#KEUZE 3'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 260,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 261,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '140e97de-b79b-4f1f-a0d5-b27654393bf3',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 262,
        'Name': 'Inlaatverwarming',
        'Prefix': 'R8_',
        'R': 263,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '478209c1-a9d3-4c98-83a7-7454ecb5f952',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 264,
        'Name': 'Vloerverwarming',
        'Prefix': 'R9_',
        'R': 265,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd6304a5c-68a1-419f-b7cf-2d50e927f337',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 266,
        'Name': 'Nestverwarming',
        'Prefix': 'R10_',
        'R': 267,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '09d4f56a-f1d0-43d0-ae16-77fda161afc4',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 268,
        'Name': 'Overige',
        'Prefix': null,
        'R': 277,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '534c5685-1824-4387-9760-4c794b61dc8e',
        'Version': 220,
        'Visible': 'or nz $R11_KEUZE nz $R12_KEUZE nz $R17_KEUZE eq $AFD_TEMP_TYPE 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 269,
        'Name': 'Alarm_Koeling',
        'Prefix': 'R11_',
        'R': 270,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'cc2a92ce-e01e-4ce8-8302-cee48b5b8303',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 271,
        'Name': 'Alarm_RV',
        'Prefix': 'R12_',
        'R': 272,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9633b233-bd09-44df-b10e-9cc24dd960a7',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 273,
        'Name': 'Alarm_CO2',
        'Prefix': 'CO2_',
        'R': 274,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '95d4170e-73f4-49e9-8323-f822da48b436',
        'Version': 220,
        'Visible': 'nz $#TYPE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 275,
        'Name': 'TemperatuurBewaking',
        'Prefix': 'R17_',
        'R': 276,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '41abad56-6c0c-4c80-858c-5e4c06fcc906',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 278,
        'Name': 'LaatsteAlarmenAfdeling',
        'Prefix': 'ALA_',
        'R': 279,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'abacd657-3476-4c06-a855-6747a3110253',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 280,
        'Name': 'History',
        'Prefix': null,
        'R': 281,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '45b5edd2-7744-41f0-9b2e-97e18fa3402a',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 283,
        'Name': 'AfdStatus',
        'Prefix': null,
        'R': 284,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ab51772c-32be-4c60-ae13-1b00687bc608',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 285,
        'Name': 'Installatie',
        'Prefix': null,
        'R': 416,
        'Reference': null,
        'Replaces': null,
        'Role': 500,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 286,
        'Name': 'Apparaat',
        'Prefix': 'AFD_',
        'R': 287,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'aace51c6-4c17-4557-a5c0-ab465d8f82a1',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 288,
        'Name': 'Regelingen',
        'Prefix': null,
        'R': 341,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd982e8f5-fc5c-47e6-b781-e37bc549209a',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 289,
        'Name': 'Ventilatieregeling',
        'Prefix': null,
        'R': 298,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'a349d9e5-771f-44b5-85af-f962c22f294c',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 290,
        'Name': 'Opties',
        'Prefix': null,
        'R': 291,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ae3dab3f-8d46-4fbe-8f42-c86729bb283c',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 292,
        'Name': 'Compensaties',
        'Prefix': null,
        'R': 293,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '6c35b4d9-50a9-434d-ba85-41afe75eb208',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 294,
        'Name': 'Stappenregeling_r',
        'Prefix': 'R2_',
        'R': 295,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b4a3425f-6fd8-4349-ba5e-223fe7493461',
        'Version': 220,
        'Visible': 'eq $R1_KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 296,
        'Name': 'Diafragmaklep',
        'Prefix': 'R3_',
        'R': 297,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd5ab38f1-95d5-4243-9d61-be1a26970bc1',
        'Version': 220,
        'Visible': 'eq $R1_KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 299,
        'Name': 'Mengventilator',
        'Prefix': 'R14_',
        'R': 300,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '4348e7ec-d107-4647-8c85-e963bdc691b6',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 301,
        'Name': 'Bypassklep',
        'Prefix': null,
        'R': 302,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '02018e5a-7ad3-457c-b120-122492957e2d',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 303,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 310,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '74cdc98d-b61a-4574-91f4-53bd30ff5965',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 304,
        'Name': 'Opties',
        'Prefix': 'R5_',
        'R': 305,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '5aad5112-309f-4017-bb2f-258f264b69b7',
        'Version': 220,
        'Visible': 'and ne $#KEUZE 3 ne $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 306,
        'Name': 'OptiesDruk',
        'Prefix': 'R5_',
        'R': 307,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '340252ba-f0ab-472f-906b-134b81c104b4',
        'Version': 220,
        'Visible': 'or eq $#KEUZE 3 eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 308,
        'Name': 'MCAklep',
        'Prefix': 'R5_',
        'R': 309,
        'Reference': null,
        'Replaces': null,
        'Role': 200,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd56c12a2-7935-4d32-84f6-11a3d3179219',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 311,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 318,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '74cdc98d-b61a-4574-91f4-53bd30ff5965',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 312,
        'Name': 'Opties',
        'Prefix': 'R6_',
        'R': 313,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '5aad5112-309f-4017-bb2f-258f264b69b7',
        'Version': 220,
        'Visible': 'and ne $#KEUZE 3 ne $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 314,
        'Name': 'OptiesDruk',
        'Prefix': 'R6_',
        'R': 315,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '340252ba-f0ab-472f-906b-134b81c104b4',
        'Version': 220,
        'Visible': 'or eq $#KEUZE 3 eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 316,
        'Name': 'MCAklep',
        'Prefix': 'R6_',
        'R': 317,
        'Reference': null,
        'Replaces': null,
        'Role': 200,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd56c12a2-7935-4d32-84f6-11a3d3179219',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 319,
        'Name': 'Afdelingsverwarming',
        'Prefix': 'R7_',
        'R': 322,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'c7cb6d33-464f-4fec-8325-29a0c089b55b',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 320,
        'Name': 'Opties',
        'Prefix': 'R7_',
        'R': 321,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 323,
        'Name': 'Inlaatverwarming',
        'Prefix': 'R8_',
        'R': 326,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'fe97a256-2e63-4daf-8384-84ae3c7941c7',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 324,
        'Name': 'Opties',
        'Prefix': 'R8_',
        'R': 325,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 327,
        'Name': 'Vloerverwarming',
        'Prefix': 'R9_',
        'R': 330,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '783a7c99-f6b1-4652-bb7b-81b520d54337',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 328,
        'Name': 'Opties',
        'Prefix': 'R9_',
        'R': 329,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 331,
        'Name': 'Nestverwarming',
        'Prefix': 'R10_',
        'R': 334,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'da5106fc-f36f-42cc-ab05-9488d1fdd1bf',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 332,
        'Name': 'Opties',
        'Prefix': 'R10_',
        'R': 333,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 335,
        'Name': 'Koeling',
        'Prefix': 'R11_',
        'R': 336,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'e4b70a14-3e18-4410-b5b2-49d05539a842',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 337,
        'Name': 'RV',
        'Prefix': 'R12_',
        'R': 338,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b8b032a8-849a-483b-94fc-ff75ee70a3c6',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 339,
        'Name': 'Schakelklok',
        'Prefix': 'R13_',
        'R': 340,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '3fe67069-5308-4b6a-8f5f-2b8a9a7dde61',
        'Version': 220,
        'Visible': 'nz $#KEUZE'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 342,
        'Name': 'Ventilatie',
        'Prefix': null,
        'R': 379,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 343,
        'Name': 'Afdelingsventilatie',
        'Prefix': null,
        'R': 354,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'a349d9e5-771f-44b5-85af-f962c22f294c',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 344,
        'Name': 'Opties',
        'Prefix': null,
        'R': 347,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'ae3dab3f-8d46-4fbe-8f42-c86729bb283c',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 345,
        'Name': 'PartieleVentilatie',
        'Prefix': null,
        'R': 346,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'c182c87e-f5ac-4369-916a-a9db8525c0bc',
        'Version': 220,
        'Visible': 'eq $APP_SPEC_KOPIEA 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 348,
        'Name': 'Compensaties',
        'Prefix': null,
        'R': 349,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '6c35b4d9-50a9-434d-ba85-41afe75eb208',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 350,
        'Name': 'Stappenregeling',
        'Prefix': 'R2_',
        'R': 351,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'b4a3425f-6fd8-4349-ba5e-223fe7493461',
        'Version': 220,
        'Visible': 'and eq $R1_KEUZE 1 neq $APP_SPEC_KOPIEA 2'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 352,
        'Name': 'Diafragmaklep',
        'Prefix': 'R3_',
        'R': 353,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd5ab38f1-95d5-4243-9d61-be1a26970bc1',
        'Version': 220,
        'Visible': 'eq $R1_KEUZE 1'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 355,
        'Name': 'Mengventilator',
        'Prefix': null,
        'R': 356,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '4348e7ec-d107-4647-8c85-e963bdc691b6',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 357,
        'Name': 'Bypassklep',
        'Prefix': null,
        'R': 358,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '02018e5a-7ad3-457c-b120-122492957e2d',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 359,
        'Name': 'Inlaatklep_r,1',
        'Prefix': 'R5_',
        'R': 368,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '7cde7148-9040-4fee-869d-83d175c797b7',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 360,
        'Name': 'Opties',
        'Prefix': 'R5_',
        'R': 361,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '5aad5112-309f-4017-bb2f-258f264b69b7',
        'Version': 220,
        'Visible': 'and ne $#KEUZE 3 ne $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 362,
        'Name': 'OptiesDruk',
        'Prefix': 'R5_,C5_',
        'R': 363,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '340252ba-f0ab-472f-906b-134b81c104b4',
        'Version': 220,
        'Visible': 'or eq $#KEUZE 3 eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 364,
        'Name': 'MCAklep',
        'Prefix': 'R5_',
        'R': 365,
        'Reference': null,
        'Replaces': null,
        'Role': 200,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd56c12a2-7935-4d32-84f6-11a3d3179219',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 366,
        'Name': 'DataMCAklep',
        'Prefix': 'R5_',
        'R': 367,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '914e1f7c-ec33-4442-b037-8c729fa25bd9',
        'Version': 220,
        'Visible': 'and [ eq $#KEUZE 4 ] [ ne $#MCA_INST 2 ]'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 369,
        'Name': 'Inlaatklep_r,2',
        'Prefix': 'R6_',
        'R': 378,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '7cde7148-9040-4fee-869d-83d175c797b7',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 370,
        'Name': 'Opties',
        'Prefix': 'R6_',
        'R': 371,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '5aad5112-309f-4017-bb2f-258f264b69b7',
        'Version': 220,
        'Visible': 'and ne $#KEUZE 3 ne $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 372,
        'Name': 'OptiesDruk',
        'Prefix': 'R6_',
        'R': 373,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '340252ba-f0ab-472f-906b-134b81c104b4',
        'Version': 220,
        'Visible': 'or eq $#KEUZE 3 eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 374,
        'Name': 'MCAklep',
        'Prefix': 'R6_',
        'R': 375,
        'Reference': null,
        'Replaces': null,
        'Role': 200,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'd56c12a2-7935-4d32-84f6-11a3d3179219',
        'Version': 220,
        'Visible': 'eq $#KEUZE 4'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 376,
        'Name': 'DataMCAklep',
        'Prefix': 'R6_',
        'R': 377,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '914e1f7c-ec33-4442-b037-8c729fa25bd9',
        'Version': 220,
        'Visible': 'and [ eq $#KEUZE 4 ] [ ne $#MCA_INST 2 ]'
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 380,
        'Name': 'Verwarmingen',
        'Prefix': null,
        'R': 399,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 381,
        'Name': 'Afdelingsverwarming',
        'Prefix': 'R7_',
        'R': 384,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '37eeb1fe-8a1c-48c4-88a1-17e58235bf24',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 382,
        'Name': 'Opties',
        'Prefix': 'R7_',
        'R': 383,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 385,
        'Name': 'Inlaatverwarming',
        'Prefix': 'R8_',
        'R': 390,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '3809ae9a-d520-4e73-970d-47fbc9e80c76',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 386,
        'Name': 'Opties',
        'Prefix': 'R8_',
        'R': 387,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 388,
        'Name': 'Vorstbeveiliging',
        'Prefix': 'R8_',
        'R': 389,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '74f151bf-5f92-4d40-95fe-6beca3490b0f',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 391,
        'Name': 'Vloerverwarming',
        'Prefix': 'R9_',
        'R': 394,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '783a7c99-f6b1-4652-bb7b-81b520d54337',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 392,
        'Name': 'Opties',
        'Prefix': 'R9_',
        'R': 393,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 395,
        'Name': 'Nestverwarming',
        'Prefix': 'R10_',
        'R': 398,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1021511a-440d-48f3-b244-f12a2f14dca8',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 396,
        'Name': 'Opties',
        'Prefix': 'R10_',
        'R': 397,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9ddea281-916a-41ef-9ff6-19f51cfeb074',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 400,
        'Name': 'OverigeRegelingen',
        'Prefix': null,
        'R': 415,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': null,
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 401,
        'Name': 'Koeling',
        'Prefix': 'R11_',
        'R': 402,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'cfebb371-21fa-4449-a2f2-f184fe665afd',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 403,
        'Name': 'RV',
        'Prefix': 'R12_',
        'R': 404,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '1bede7f4-cc0d-4730-b0f6-386c28ea294d',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 405,
        'Name': 'CO2',
        'Prefix': 'CO2_',
        'R': 406,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '9cbbf937-a5bf-4407-a30f-4b57c27c4218',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 407,
        'Name': 'Schakelklok',
        'Prefix': 'R13_',
        'R': 408,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '3fe67069-5308-4b6a-8f5f-2b8a9a7dde61',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 409,
        'Name': 'Waterteller',
        'Prefix': 'R15_',
        'R': 410,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': '$#TEKST',
        'TreeVersion': 0,
        'Type': '587a822b-65f8-4cec-9232-f7f3dc84c646',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 411,
        'Name': 'TemperatuurBewaking',
        'Prefix': 'R17_',
        'R': 412,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': '5b5a1ec5-aa97-4b7c-aee9-c8cf4f709bd6',
        'Version': 220,
        'Visible': null
    },
    {
        'Enabled': null,
        'Extra': null,
        'Hardware': 151,
        'L': 413,
        'Name': 'Inst_Voelers',
        'Prefix': null,
        'R': 414,
        'Reference': null,
        'Replaces': null,
        'Role': 800,
        'Text': null,
        'TreeVersion': 0,
        'Type': 'db53d410-ecc2-425b-a8df-0f98b58ed9e4',
        'Version': 220,
        'Visible': null
    }
];
