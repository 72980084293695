import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAnalytics, storeColumnsSuccess } from '../state/slice';
import { useTranslation } from 'react-i18next';
import sub from 'date-fns/sub';
import { reverse } from 'lodash/fp';
import { startOfDay } from 'date-fns';
import { dateWithoutTimezone } from '@utils/dates';
import DataChart from './AnalyticsTable/DataChart';
import { Link } from 'react-router-dom';
import AnalyticsSidebar from './AnalyticsTable/Sidebar';
import StienenTable from '@elementComponents/Table';
import { Button } from '@mui/material';
import IconStatistics from '!svg-react-loader?name=IconStatistics!assets/svg/icon-statistics.svg';
import IconTable from '!svg-react-loader?name=IconTable!assets/svg/icon-table.svg';
import IconEdit from '!svg-react-loader?name=IconEdit!assets/svg/icon-edit.svg';
import { SelectComponent } from '@formComponents/Select';
import { Form } from 'react-final-form';
import { DateRangePickerComponent } from '@formComponents/DatePicker';
import endOfDay from 'date-fns/endOfDay';
import isToday from 'date-fns/isToday';
const AnalyticsTable = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [view, setView] = useState('table');
    const [interval, setInterval] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [startApi, setStartApi] = useState('');
    const [endApi, setEndApi] = useState('');
    const columns = useSelector((state) => state && state.analytics && state.analytics.columns);
    const analytics = useSelector((state) => state.analytics && state.analytics && state.analytics.list && state.analytics.list.data);
    const analyticsLoading = useSelector((state) => state.analytics && state.analytics.list && state.analytics.list.loading);
    const analyticsIsLoaded = useSelector((state) => state.analytics && state.analytics.list && state.analytics.list.isLoaded);
    const analyticsDates = analytics && analytics[0] && analytics[0].data;
    const analyticsDatesReversed = analyticsDates && analyticsDates.length > 0 && reverse(analyticsDates);
    console.log('analytics=', analytics);
    const columnNames = [
        {
            index: 1,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 1,
            name: 'R1_Tmom',
            columnName: '1_R1_Tmom'
        }, {
            index: 2,
            deviceId: 'ed228825-635f-48b8-8081-0f145b3c1f43',
            uuid: 2,
            name: 'R1_Tmom',
            columnName: '2_R1_Tmom'
        }, {
            index: 3,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 3,
            name: 'R0_VENT',
            columnName: '3_R0_VENT'
        }, {
            index: 4,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 4,
            name: 'MIX_CAPDIER',
            columnName: '4_MIX_CAPDIER'
        }, {
            index: 5,
            deviceId: '568f7ab2-10d2-46f2-b6ba-99f73b36588f',
            uuid: 5,
            name: 'MIX_BEDRIJFT',
            columnName: '5_MIX_BEDRIJFT'
        }
    ];
    const headers = [
        {
            id: 'dateTime',
            numeric: false,
            label: t('analytics.dateAndTime')
        }
    ];
    columnNames && columnNames.map((column) => {
        headers.push({
            id: column.columnName,
            numeric: true,
            label: column.name
        });
    });
    const analyticsData = analyticsDates && analyticsDates.map((data) => {
        const obj = {};
        obj['dateTime'] = data.dateTime;
        columnNames && columnNames.map((column) => {
            const findVar = analytics.find((dataVar) => (dataVar.varName === column.name && dataVar.deviceId === column.deviceId))?.data;
            const findData = findVar && findVar.find((dataValue) => dataValue.dateTime === data.dateTime);
            obj[column.columnName] = findData && findData.value !== null ? findData.value : '-';
        });
        return obj;
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    useEffect(() => {
        setView(urlParams.get('view') ? urlParams.get('view') : null);
    }, [queryString]);
    useEffect(() => {
        dispatch(storeColumnsSuccess(columnNames));
    }, []);
    const IntervalOptions = () => {
        return [
            { key: '-4', value: '-4', text: t('intervals.millisecond') },
            { key: '-3', value: '-3', text: t('intervals.seconds') },
            { key: '-2', value: '-2', text: t('intervals.minutes') },
            { key: '-1', value: '-1', text: t('intervals.hours') },
            { key: '0', value: '0', text: t('intervals.days') },
            { key: '1', value: '1', text: t('intervals.weeks') },
            { key: '2', value: '2', text: t('intervals.months') },
            { key: '3', value: '3', text: t('intervals.quarters') },
            { key: '4', value: '4', text: t('intervals.years') },
            { key: '5', value: '5', text: t('intervals.minutes10') }
        ];
    };
    const numberOfDays = view === 'chart' ? 30 : 30;
    useEffect(() => {
        console.log('USE EFFECT 1');
        const currentDate = new Date();
        const endDate = currentDate;
        const startDate = sub(currentDate, { days: numberOfDays });
        const endDateApi = dateWithoutTimezone(currentDate);
        const startDateApi = dateWithoutTimezone(startOfDay(sub(currentDate, { days: numberOfDays })));
        startDate && (setStart(startDate),
            setStartApi(startDateApi));
        endDate && (setEnd(endDate),
            setEndApi(endDateApi));
        const intervalNumber = view === 'chart' ? '-1' : '-1';
        setInterval(intervalNumber);
    }, [view]);
    useEffect(() => {
        const columnsApi = columns && columns.map((item) => {
            return {
                'deviceId': item.deviceId,
                'varName': item.name
            };
        });
        columnsApi && startApi && endApi && interval && (console.log('fetchAnalytics'),
            dispatch(fetchAnalytics(columnsApi, startApi, endApi, interval)));
    }, [interval, startApi]);
    const onClickEditTable = () => {
        setVisible(true);
    };
    const onChangeInterval = (event) => {
        const value = event;
        setInterval(value);
    };
    const onChangeDateRange = (date) => {
        setStart(date[0]);
        setStartApi(dateWithoutTimezone(date[0]));
        const isDateToday = isToday(date[1]);
        setEnd(date[1]);
        setEndApi(isDateToday ? dateWithoutTimezone(new Date()) : dateWithoutTimezone(endOfDay(date[1])));
    };
    const isLoading = analyticsLoading
        ? true
        : analyticsIsLoaded && (analyticsDates && analyticsDates.length > 0) && (analyticsData && analyticsData.length > 0)
            ? false
            : analyticsIsLoaded && (analyticsDates && analyticsDates.length === 0)
                ? false
                : true;
    console.log('isLoading=', isLoading);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'justify-content-space-between mb2' },
            React.createElement("div", { className: 'justify-content-start' },
                React.createElement(Form, { onSubmit: () => { }, initialValues: {
                        Interval: interval
                    }, render: ({ values }) => {
                        console.log('values=', values);
                        return (React.createElement("form", { className: 'justify-content-start' },
                            React.createElement(DateRangePickerComponent, { defaultValue: [start, end], cleanable: false, name: 'DateRange', label: t('analytics.dateRange'), onChangeDate: onChangeDateRange, className: 'mr1' }),
                            React.createElement(SelectComponent, { name: 'Interval', label: t('analytics.interval'), placeholder: t('analytics.interval'), options: IntervalOptions(), className: 'analytics-interval-select', onSelect: onChangeInterval })));
                    } })),
            React.createElement("div", { className: 'align-items-end' },
                view === 'table' || view === null ?
                    React.createElement(Link, { to: '/analytics?view=chart', onClick: () => setView('chart') },
                        React.createElement(Button, { variant: 'contained', startIcon: React.createElement(IconStatistics, null) }, t('analytics.viewAsChart')))
                    : view === 'chart' &&
                        React.createElement(Link, { to: '/analytics?view=table', onClick: () => setView('table') },
                            React.createElement(Button, { variant: 'contained', startIcon: React.createElement(IconTable, null) }, t('analytics.viewAsTable'))),
                React.createElement(Button, { variant: 'contained', className: 'ml1', onClick: () => onClickEditTable(), startIcon: React.createElement(IconEdit, null) }, t('main.edit')))),
        analyticsDatesReversed && analyticsDatesReversed.length && React.createElement("p", { className: 'mt1' }, t('analytics.recordsText', { records: analyticsDatesReversed.length })),
        view === 'table' || view === null ?
            React.createElement(React.Fragment, null,
                React.createElement(StienenTable, { searchBar: true, showCheckboxes: true, defaultOrderDesc: true, isLoading: isLoading, numberOfRows: 10, headers: headers, rows: analyticsData && analyticsData.length > 0 ? analyticsData : [], defaultOrder: 'dateTime', title: t('main.data') }))
            : view === 'chart' &&
                React.createElement(DataChart, { columnNames: columns, analytics: analytics, analyticsLoading: analyticsLoading, analyticsDates: analyticsDates }),
        React.createElement(AnalyticsSidebar, { visible: visible, setVisible: setVisible })));
};
export default AnalyticsTable;
