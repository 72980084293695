import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Segment, TabPane } from 'semantic-ui-react';
import { getHardwareById } from '@utils/hardware';
import IconRefresh from '!svg-react-loader?name=IconRefresh!assets/svg/icon-refresh.svg';
import IconFullScreen from '!svg-react-loader?name=IconFullScreen!assets/svg/icon-fullscreen.svg';
import DeviceStatusDot from 'app/layout/components/DeviceStatusDot';
import IconAspectRatio from '!svg-react-loader?name=IconAspectRatio!assets/svg/icon-aspect-ratio.svg';
import { isMobile } from 'react-device-detect';
import { Button } from '@mui/material';
const TabRemoteFlutter = (props) => {
    const { t } = useTranslation();
    const { device } = props;
    const [fitToHeight, setFitToHeight] = useState(false);
    const remoteRef = useRef(null);
    const products = useSelector((state) => state.subscription && state.subscription.products && state.subscription.products.data);
    const hardware = device && products && getHardwareById(device.hardware, products);
    console.log('device=', device);
    console.log('hardware=', hardware);
    const remoteUrl = 'http://remote.test.farmconnect.eu/pl_9600/1.0.0';
    const onClickFullScreen = () => {
        window.open(remoteUrl, '_blank');
    };
    const onClickRefresh = () => {
        document.getElementById('flutter-remote-iframe').src += '';
    };
    const onClickFitToHeight = () => {
        setFitToHeight(!fitToHeight);
        handleResize(!fitToHeight);
        onClickRefresh();
    };
    const aspectRatio = [16, 9];
    const handleResize = (value) => {
        if (value && remoteRef && remoteRef.current) {
            const viewportHeight = window.innerHeight;
            const currentHeight = viewportHeight - 440;
            const currentWidth = aspectRatio && (currentHeight / aspectRatio[1]) * aspectRatio[0];
            remoteRef.current.style.height = `${currentHeight}px`;
            remoteRef.current.style.width = `${currentWidth}px`;
        }
        else if (remoteRef && remoteRef.current) {
            remoteRef.current.style.width = '100%';
            const currentWidth = remoteRef.current.offsetWidth;
            const currentHeight = aspectRatio && (currentWidth / aspectRatio[0]) * aspectRatio[1];
            remoteRef.current.style.height = `${currentHeight}px`;
        }
    };
    useEffect(() => {
        handleResize(fitToHeight);
    }, []);
    useEffect(() => {
        window.addEventListener('resize', () => handleResize(fitToHeight));
        return () => {
            window.removeEventListener('resize', () => handleResize(fitToHeight));
        };
    }, []);
    return (React.createElement(TabPane, { className: 'no-border-and-padding-tab' },
        React.createElement(Segment, { className: `segment-with-header-border info-bar ${isMobile ? 'info-bar-mobile' : 'justify-content-space-between'}` },
            React.createElement("div", { className: 'header-border' }),
            React.createElement("div", null,
                React.createElement("p", { className: 'justify-content-start' },
                    React.createElement("img", { src: hardware ? hardware.images[0] : 'https://dev.farmconnect.eu/img/UnavailableDevice.b0365816.png' }),
                    React.createElement("span", null, hardware && hardware.name),
                    React.createElement(DeviceStatusDot, { online: device && device.online }))),
            React.createElement("div", { className: 'align-items-center' }, device && device.online &&
                React.createElement(React.Fragment, null,
                    React.createElement(Button, { variant: 'contained', startIcon: React.createElement(IconAspectRatio, null), onClick: () => onClickFitToHeight() }, !isMobile && fitToHeight
                        ? t('devices.fitToWidth')
                        : !isMobile && t('devices.fitToHeight')),
                    React.createElement(Button, { variant: 'contained', className: 'ml-half', startIcon: React.createElement(IconFullScreen, null), onClick: () => onClickFullScreen() }, !isMobile && t('devices.openExternal')),
                    React.createElement(Button, { variant: 'contained', className: 'ml-half', startIcon: React.createElement(IconRefresh, null), onClick: () => onClickRefresh() }, !isMobile && t('devices.refreshConnection'))))),
        device && device.online
            &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'header-border' }),
                    React.createElement("iframe", { id: 'flutter-remote-iframe', ref: remoteRef, src: remoteUrl }))));
};
export default TabRemoteFlutter;
