import { useEffect, useState } from 'react';
import { logoutSuccess, storeUserSuccess } from 'app/login/state/slice';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { clearCustomerSuccess, clearCustomersSuccess } from 'app/customers/state/slice';
import { clearLocationsSuccess } from 'app/locations/state/slice';
import { clearDealerInvitesSuccess } from 'app/dealer/state/slice';
import { clearEventLogSuccess } from 'app/locations/components/Logs/eventLog/state/slice';
import { clearMetricsSuccess, clearProductsSuccess } from 'app/admin/subscription/state/slice';
import { toastrSuccess } from '@elementComponents/Toastr';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
const UserSession = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [time, setTime] = useState(new Date());
    const auth = useSelector((state) => state.auth && state.auth);
    const refreshUserSession = async () => {
        console.log('REFRESH USER SESSION');
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = cognitoUser.signInUserSession;
            cognitoUser.refreshSession(currentSession.refreshToken, () => {
                const userObject = {
                    userId: cognitoUser.username,
                    email: cognitoUser.attributes.email,
                    emailVerified: cognitoUser.attributes.email_verified,
                    firstName: cognitoUser.attributes.name,
                    lastName: cognitoUser.attributes.family_name,
                    userGroups: cognitoUser.signInUserSession.idToken.payload['cognito:groups'],
                    jwtToken: cognitoUser.signInUserSession.idToken.jwtToken
                };
                console.log(`[${time.toISOString()}] => Refresh Cognito User Session`);
                dispatch(storeUserSuccess(userObject));
            });
        }
        catch (e) {
            console.error(e);
            Auth.signOut()
                .then(() => {
                toastrSuccess(t('auth.logoutMessageHeader'), t('auth.logoutMessageText'));
                dispatch(logoutSuccess());
                dispatch(clearCustomerSuccess());
                dispatch(clearCustomersSuccess());
                dispatch(clearLocationsSuccess());
                dispatch(clearDealerInvitesSuccess());
                dispatch(clearEventLogSuccess());
                dispatch(clearMetricsSuccess());
                dispatch(clearProductsSuccess());
                history.push('/login');
            })
                .catch((err) => console.log(err));
        }
    };
    useEffect(() => {
        const interval = auth && setInterval(() => {
            console.log('REFRESH EVERY 10 MINUTES');
            setTime(new Date());
            auth && auth.success && refreshUserSession();
        }, 1000 * 60 * 10);
        return () => clearInterval(interval);
    }, []);
    return null;
};
export default UserSession;
