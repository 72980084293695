import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerId } from '@utils/customer';
import { getUserId, isCustomerAccount, isMechanicAccount, isServiceAccount } from '@utils/auth';
import { fetchCustomer, fetchCustomers } from 'app/customers/state/slice';
import { fetchLocations } from 'app/locations/state/slice';
import { fetchUser } from 'app/admin/users/state/slice';
import { fetchProducts } from 'app/admin/subscription/state/slice';
import { fetchDealerInvites } from 'app/dealer/state/slice';
const MainApiCalls = () => {
    const dispatch = useDispatch();
    const customerId = getCustomerId();
    const userId = getUserId();
    const serviceAccount = isServiceAccount();
    const mechanicAccount = isMechanicAccount();
    const customerAccount = isCustomerAccount();
    const auth = useSelector((state) => state.auth && state.auth);
    const currentCustomer = useSelector((state) => state.customers && state.customers.current);
    const customersIsLoaded = useSelector((state) => state.customers && state.customers.list && state.customers.list.isLoaded);
    const locationsIsLoaded = useSelector((state) => state.locations && state.locations.list && state.locations.list.isLoaded);
    useEffect(() => {
        auth && auth.success && customerId && dispatch(fetchProducts());
        auth && auth.success && userId && dispatch(fetchUser(userId));
        auth && auth.success && customerId && dispatch(fetchDealerInvites(customerId));
    }, [userId, customerId]);
    useEffect(() => {
        auth && auth.success && customerId && currentCustomer && !currentCustomer.id && dispatch(fetchCustomer(customerId));
    }, [auth, customerId, currentCustomer]);
    useEffect(() => {
        auth && auth.success && customerAccount && currentCustomer && !currentCustomer.id && dispatch(fetchCustomer(userId));
    }, [auth, customerAccount, userId]);
    useEffect(() => {
        auth && auth.success && !customerId && serviceAccount && !mechanicAccount && customersIsLoaded !== true && dispatch(fetchCustomers());
    }, [customerId, serviceAccount, mechanicAccount]);
    useEffect(() => {
        auth && auth.success && userId && locationsIsLoaded !== true && customerAccount && currentCustomer && currentCustomer.id && dispatch(fetchLocations(userId));
    }, [userId, locationsIsLoaded, currentCustomer, customerAccount]);
    return null;
};
export default MainApiCalls;
