import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { storeTabsContentSuccess, storeTabsSuccess, storeWidgetsSuccess } from './state/slice';
import WidgetsSidebar from './components/Sidebar';
import { orderBy } from 'lodash/fp';
import TabContent from './components/TabContent';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import SortableItem from './components/Sidebar/TabWidgets/SortableItem';
import { tabContentArray, tabsArray, widgetsArray } from '@constants/widgets';
import { isMobile } from 'react-device-detect';
import { Box, Button } from '@mui/material';
import IconEdit from '!svg-react-loader?name=IconEdit!assets/svg/icon-edit.svg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
const Widgets = () => {
    const { t } = useTranslation();
    const { tabUuid } = useParams();
    const dispatch = useDispatch();
    const [value, setValue] = useState('1');
    const [activeItem, setActiveItem] = useState(null);
    const [isAdded, setIsAdded] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    const [tabCurrentContent, setTabCurrentContent] = React.useState([]);
    const [indexOfNewItem, setIndexOfNewItem] = React.useState();
    const tabs = useSelector((state) => state && state.widgets && state.widgets.tabs);
    const tabsContent = useSelector((state) => state && state.widgets && state.widgets.content);
    const widgets = useSelector((state) => state && state.widgets && state.widgets.widgets);
    const tabsSorted = orderBy([(tab) => tab && tab.index], 'asc', tabs);
    useEffect(() => {
        dispatch(storeTabsSuccess(tabsArray));
    }, [tabsArray]);
    useEffect(() => {
        dispatch(storeWidgetsSuccess(widgetsArray));
    }, [widgetsArray]);
    useEffect(() => {
        dispatch(storeTabsContentSuccess([
            {
                uuid: '110d8cfe-6b7d-430c-8635-35d5db3dd6ad',
                content: tabContentArray
            }
        ]));
        setIndexOfNewItem(tabContentArray.length + 1);
    }, [tabContentArray]);
    const selectedTab = tabUuid && tabs && tabs.find((tab) => tab.uuid === tabUuid);
    const selectedContent = tabUuid && tabsContent && tabsContent.find((tab) => tab.uuid === tabUuid);
    useEffect(() => {
        selectedTab
            ? setValue(`${selectedTab.index}`)
            : setValue('0');
        selectedContent
            ? setTabCurrentContent(selectedContent.content)
            : setTabCurrentContent([]);
    }, [tabUuid, tabs]);
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };
    const onClickEditTabs = () => {
        setVisible(true);
    };
    const tabs2 = tabsSorted && tabsSorted.map((tab) => {
        return {
            label: React.createElement("span", { className: 'tab-color-block' },
                React.createElement("span", { className: 'tab-color', style: { backgroundColor: `#${tab.color}` } }),
                tab.name),
            link: `/widgets/${tab.uuid}`,
            content: React.createElement(TabContent, { uuid: tab.uuid, activeId: activeItem && activeItem.id, tabCurrentContent: tabCurrentContent, setTabCurrentContent: setTabCurrentContent })
        };
    });
    const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates
    }));
    const handleDragStart = (event) => {
        const isExistingItem = tabCurrentContent.find((item) => item.id === event.active.id);
        if (!isExistingItem) {
            const findWidget = widgets.find((item) => item.id === event.active.id);
            setActiveItem(findWidget);
        }
        else {
            const findWidget = tabCurrentContent.find((item) => item.id === event.active.id);
            setActiveItem(findWidget);
        }
    };
    const handleDragOver = (event) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const isExistingItem = tabCurrentContent.find((item) => item.id === active.id);
            const activeIndex = tabCurrentContent.findIndex((x) => x.id === active.id);
            const overIndex = tabCurrentContent.findIndex((x) => x.id === over.id);
            const oldArray = tabCurrentContent;
            const numberOfItemsState = tabCurrentContent && tabCurrentContent.length;
            if (!isExistingItem && numberOfItemsState < indexOfNewItem && !isAdded) {
                const findWidget = widgets.find((item) => item.id === active.id);
                const newArray = findWidget && oldArray && oldArray.concat({
                    id: indexOfNewItem.toString(),
                    icon: findWidget.icon,
                    type: findWidget.type,
                    width: findWidget.width,
                    title: findWidget.title
                });
                setIndexOfNewItem(indexOfNewItem);
                if (oldArray.length === 0) {
                    setTabCurrentContent(newArray);
                    setIsAdded(true);
                }
                else {
                    let arrayUpdated = arrayMove(newArray, indexOfNewItem - 1, overIndex);
                    arrayUpdated = arrayUpdated.filter((element) => {
                        return element !== undefined;
                    });
                    setTabCurrentContent(arrayUpdated);
                    setIsAdded(true);
                }
            }
            else if (isExistingItem && (activeIndex !== -1) && (overIndex !== -1) && !isAdded) {
                if (activeIndex === overIndex)
                    return;
                setTabCurrentContent(arrayMove(tabCurrentContent, activeIndex, overIndex));
            }
        }
    };
    const handleDragEnd = (event) => {
        const { active } = event;
        const isExistingItem = tabCurrentContent.find((item) => item.id === active.id);
        setActiveItem(null);
        setIsAdded(false);
        !isExistingItem && setIndexOfNewItem(indexOfNewItem + 1);
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, t('main.widgets'))),
            !isMobile &&
                React.createElement(Button, { variant: 'contained', startIcon: React.createElement(IconEdit, null), onClick: () => onClickEditTabs() }, t('main.edit'))),
        React.createElement(DndContext, { sensors: sensors, collisionDetection: closestCenter, onDragStart: handleDragStart, onDragOver: handleDragOver, onDragEnd: handleDragEnd },
            React.createElement(Box, { className: 'stienen-tabs mb2' },
                React.createElement(TabContext, { value: value },
                    React.createElement("div", { className: 'header-border' }),
                    React.createElement(TabList, { variant: 'scrollable', allowScrollButtonsMobile: true, onChange: handleChange }, tabs2 && tabs2.map((tab, key) => {
                        return (React.createElement(Tab, { key: key, label: tab.label, value: `${key}`, component: Link, to: tab.link }));
                    })),
                    tabs2 && tabs2.map((tab, key) => {
                        return (React.createElement(TabPanel, { key: key, value: `${key}` }, tab.content));
                    }))),
            !isMobile && React.createElement(WidgetsSidebar, { visible: visible, setVisible: setVisible, tabCurrentContent: tabCurrentContent, selectedTab: selectedTab, selectedContent: selectedContent, setTabCurrentContent: setTabCurrentContent }),
            React.createElement(DragOverlay, { className: 'widgets-drag-overlay' }, activeItem ? (React.createElement(SortableItem, { handle: true, content: activeItem })) : null))));
};
export default Widgets;
