import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCookieLocale } from '@utils/locales';
import { fetchAlarmReference } from 'app/reference/state/slice';
import { getAlarmTranslation } from '@utils/reference';
import StienenTable from '@elementComponents/Table';
import { useHistory } from 'react-router-dom';
const ActiveAlarms = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const activeAlarms = useSelector((state) => state.alarms && state.alarms.active && state.alarms.active.data);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const alarmReference = useSelector((state) => state.reference && state.reference.alarm);
    const activeAlarmsData = activeAlarms && activeAlarms.length > 0 ? activeAlarms.map((alarm) => {
        const findLocation = locations.find((location) => location.id === alarm.gid);
        const findDevice = findLocation && findLocation.devices.find((device) => device.id === alarm.did);
        const hardware = findDevice.hardware;
        const version = findDevice.version;
        const language = getCookieLocale().code;
        const findReference = alarmReference && alarmReference.find((ref) => ref.hardware === hardware && ref.version === version && ref.language === language);
        if (hardware && version && language && !findReference) {
            dispatch(fetchAlarmReference(hardware, version, 'nl'));
        }
        return {
            uuid: alarm.alarmId,
            locationUuid: alarm.gid,
            deviceUuid: alarm.did,
            location: alarm.gatewayName,
            device: alarm.deviceName,
            alarm: getAlarmTranslation(hardware, version, 'nl', alarm.alarmCode, alarmReference),
            type: alarm.controlType
        };
    }) : [];
    const headers = [
        {
            id: 'location',
            numeric: false,
            label: t('alarms.location'),
        },
        {
            id: 'device',
            numeric: false,
            label: t('alarms.device'),
        },
        {
            id: 'alarm',
            numeric: false,
            label: t('alarms.alarm'),
            booleanDots: true
        },
        {
            id: 'type',
            numeric: false,
            label: t('alarms.type'),
            booleanDots: true
        },
    ];
    const onGoToAlarm = (alarm) => {
        history.push(`/locations/${alarm.locationUuid}/${alarm.deviceUuid}`);
    };
    return (React.createElement(StienenTable, { showCheckboxes: true, negativeHeaderBorder: true, numberOfRows: 5, headers: headers, rows: activeAlarmsData && activeAlarmsData, defaultOrder: 'location', title: t('main.activeAlarms'), actions: [
            {
                button: t('main.goTo'),
                onClick: onGoToAlarm
            },
        ] }));
};
export default ActiveAlarms;
