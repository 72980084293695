import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar, SidebarPushable } from 'semantic-ui-react';
import TabTabs from './Sidebar/TabTabs';
import TabWidgets from './Sidebar/TabWidgets';
import { Box, IconButton, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabOutlinedIcon from '@mui/icons-material/TabOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
const WidgetsSidebar = (props) => {
    const { t } = useTranslation();
    const { visible, setVisible, tabCurrentContent, selectedTab, selectedContent, setTabCurrentContent } = props;
    const [value, setValue] = useState('0');
    const tabs = [
        {
            label: t('widgets.tabs'),
            icon: React.createElement(TabOutlinedIcon, null),
            content: React.createElement(TabTabs, { visible: visible, setVisible: setVisible })
        },
        {
            label: t('main.widgets'),
            icon: React.createElement(WidgetsOutlinedIcon, null),
            content: React.createElement(TabWidgets, { visible: visible, setVisible: setVisible, tabCurrentContent: tabCurrentContent, selectedTab: selectedTab, selectedContent: selectedContent, setTabCurrentContent: setTabCurrentContent })
        },
    ];
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };
    const onCloseSideBar = () => {
        setVisible(false);
        setTabCurrentContent(selectedContent.content);
    };
    return (React.createElement(SidebarPushable, { className: 'sidebar-widgets' },
        React.createElement(Sidebar, { direction: 'right', animation: 'overlay', visible: visible },
            React.createElement(IconButton, { onClick: () => onCloseSideBar(), className: 'close-sidebar-btn' },
                React.createElement("span", { className: 'hover-bg' },
                    React.createElement(CloseOutlinedIcon, null))),
            React.createElement(Box, { className: 'stienen-tabs sidebar-stienen-tabs mb2' },
                React.createElement(TabContext, { value: value },
                    React.createElement("div", { className: 'header-border' }),
                    React.createElement(TabList, { variant: 'scrollable', allowScrollButtonsMobile: true, onChange: handleChange }, tabs && tabs.map((tab, key) => {
                        return (React.createElement(Tab, { key: key, label: tab.label, icon: tab.icon, iconPosition: 'start', value: `${key}` }));
                    })),
                    tabs && tabs.map((tab, key) => {
                        return (React.createElement(TabPanel, { key: key, value: `${key}` }, tab.content));
                    }))))));
};
export default WidgetsSidebar;
