import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { Segment } from 'semantic-ui-react';
import IconDragIndicator from '!svg-react-loader?name=IconDragIndicator!assets/svg/icon-drag-indicator.svg';
import IconDelete from '!svg-react-loader?name=IconDelete!assets/svg/icon-delete.svg';
import { motion } from 'framer-motion';
import { getWidgetIcon } from '@utils/widgetIcons';
import { isMobile } from 'react-device-detect';
import ListWidget from '@widgets/ListWidget';
import LineChartWidget from '@widgets/LineChartWidget';
import { useTranslation } from 'react-i18next';
import { StienenIconButtonWithTooltip } from '@elementComponents/IconButton';
const SortableItem = (props) => {
    const { t } = useTranslation();
    const { deleteIcon, onClickDelete, content: { id, icon, title, type, width } } = props;
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
        disabled: isMobile
    });
    return (React.createElement(motion.div, { layoutId: id, ref: setNodeRef, transition: {
            type: 'spring',
            duration: isDragging ? 0 : 0.6
        }, className: 'widget', style: {
            transform: transform
                ? `translate(${transform.x}px, ${transform.y}px)`
                : 'none',
            transition
        } },
        React.createElement("div", { className: 'header-border' }),
        React.createElement(Segment, null,
            React.createElement("div", { className: 'header' },
                React.createElement("button", { className: 'header-button-bar', ...listeners, ...attributes },
                    React.createElement("h3", { className: 'title' },
                        icon && React.createElement("span", null, getWidgetIcon(icon)),
                        title && title),
                    React.createElement("div", null,
                        React.createElement("div", { className: 'drag' }, !isMobile && React.createElement(IconDragIndicator, null)))),
                deleteIcon &&
                    React.createElement("div", { className: 'delete' },
                        React.createElement(StienenIconButtonWithTooltip, { placement: 'top', title: t('main.delete'), onClick: () => onClickDelete(id), content: React.createElement(IconDelete, null) }))),
            type === 'list' ? React.createElement(ListWidget, { content: props.content })
                : type === 'lineChart' ? React.createElement(LineChartWidget, { content: props.content })
                    :
                        React.createElement("div", null,
                            id ? id : 0,
                            " / ",
                            type && type,
                            " / ",
                            width && width))));
};
export default SortableItem;
