import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCustomerId } from '@utils/customer';
import { fetchCustomer } from 'app/customers/state/slice';
const CompanyName = () => {
    const customerId = getCustomerId();
    const dispatch = useDispatch();
    const currentCustomer = useSelector((state) => state.customers && state.customers.current);
    useEffect(() => {
        customerId && dispatch(fetchCustomer(customerId));
    }, []);
    return (React.createElement("div", { className: 'company-name' }, currentCustomer.companyName &&
        React.createElement("h1", null,
            React.createElement(Link, { to: '/dashboard' }, currentCustomer.companyName))));
};
export default CompanyName;
