import React, { useEffect, useState } from 'react';
import Logo from './Header/Logo';
import Icons from './Header/Icons';
import HeaderDate from './Header/Date';
import { getSidebarWidth } from '@utils/sidebar';
import { useSelector } from 'react-redux';
import { isDealerAccount } from '@utils/auth';
import HeaderAlarms from './Header/Alarms';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import MainMenuMobile from './Sidebar/MenuMobile';
import IconMenuMobile from '!svg-react-loader?name=IconMenuMobile!assets/svg/icon-menu.svg';
import CompanyName from './Header/CompanyName';
const Header = (props) => {
    const dealerAccount = isDealerAccount();
    const history = useHistory();
    const [openMobile, setOpenMobile] = useState(false);
    const { onClickSidebarBtn } = props;
    const auth = useSelector((state) => state.auth && state.auth);
    const currentCustomer = useSelector((state) => state.customers && state.customers.current);
    useEffect(() => {
        auth && auth.success && dealerAccount && (history.push('/dealer-login'));
    }, [auth, dealerAccount]);
    return (React.createElement(React.Fragment, null,
        currentCustomer && currentCustomer.id && React.createElement(HeaderAlarms, null),
        isMobile && React.createElement(MainMenuMobile, { openMobile: openMobile, setOpenMobile: setOpenMobile }),
        React.createElement("div", { id: 'header', className: isMobile ? 'header-mobile' : '', style: {
                width: isMobile ? '100vw' : auth && !auth.success ? '100vw' : `calc(100vw - ${getSidebarWidth()}px)`,
            } },
            React.createElement("div", { className: 'header-column-left' },
                auth && auth.success && isMobile && (React.createElement("div", { className: 'menu-mobile-icon', onClick: openMobile
                        ? () => setOpenMobile(false)
                        : () => setOpenMobile(true) },
                    React.createElement(IconMenuMobile, null))),
                auth && auth.success && React.createElement(CompanyName, null)),
            React.createElement("div", { className: 'header-column-right' },
                auth && auth.success && (React.createElement(React.Fragment, null,
                    !isMobile && React.createElement(HeaderDate, null),
                    React.createElement(Icons, { onClickSidebarBtn: onClickSidebarBtn }))),
                React.createElement(Logo, null)))));
};
export default Header;
