import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { startOfDay, sub } from 'date-fns';
import { dateWithoutTimezone, formatToDutchDateAndTime } from '@utils/dates';
import { useParams } from 'react-router-dom';
import { getDeviceById } from '@utils/devices';
import { fetchAlarmHistoryRange } from 'app/alarms/state/slice';
import { getAlarmTranslation } from '@utils/reference';
import { fetchAlarmReference } from 'app/reference/state/slice';
import { getCookieLocale } from '@utils/locales';
import StienenTable from '@elementComponents/Table';
import { Form } from 'react-final-form';
import { DateRangePickerComponent } from '@formComponents/DatePicker';
import isToday from 'date-fns/isToday';
import endOfDay from 'date-fns/endOfDay';
const AlarmsHistory = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { deviceId } = useParams();
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [startApi, setStartApi] = useState('');
    const [endApi, setEndApi] = useState('');
    const alarmsList = useSelector((state) => state.alarms && state.alarms.device && state.alarms.device.data);
    const alarmsListIsLoaded = useSelector((state) => state.alarms && state.alarms.device && state.alarms.device.isLoaded);
    const alarmReference = useSelector((state) => state.reference && state.reference.alarm);
    const locations = useSelector((state) => state.locations && state.locations.list && state.locations.list.data);
    const device = getDeviceById(deviceId, locations);
    useEffect(() => {
        const numberOfDays = 14;
        const currentDate = new Date();
        const endDate = currentDate;
        const startDate = sub(currentDate, { days: numberOfDays });
        const endDateApi = dateWithoutTimezone(currentDate);
        const startDateApi = dateWithoutTimezone(startOfDay(sub(currentDate, { days: numberOfDays })));
        startDate && (setStart(startDate),
            setStartApi(startDateApi));
        endDate && (setEnd(endDate),
            setEndApi(endDateApi));
    }, []);
    useEffect(() => {
        device && device.id && startApi && endApi && (dispatch(fetchAlarmHistoryRange(device.id, startApi, endApi)));
    }, [startApi]);
    const onChangeDateRange = (date) => {
        setStart(date[0]);
        setStartApi(dateWithoutTimezone(date[0]));
        const isDateToday = isToday(date[1]);
        setEnd(date[1]);
        setEndApi(isDateToday ? dateWithoutTimezone(new Date()) : dateWithoutTimezone(endOfDay(date[1])));
    };
    const alarmHistoryData = alarmsList && alarmsList.map((alarm) => {
        const hardware = alarm.hardware;
        const version = alarm.version;
        const language = getCookieLocale().code;
        const findReference = alarmReference && alarmReference.find((ref) => ref.hardware === hardware && ref.version === version && ref.language === language);
        if (hardware && version && language && !findReference) {
            dispatch(fetchAlarmReference(hardware, version, language));
        }
        return {
            uuid: alarm.id,
            timeStamp: alarm.timeStamp && formatToDutchDateAndTime(alarm.timeStamp),
            code: getAlarmTranslation(hardware, version, 'nl', alarm.alarmCode, alarmReference),
            version: alarm.version,
            control: alarm.controlText
        };
    });
    const headers = [
        {
            id: 'timeStamp',
            numeric: false,
            label: t('alarms.dateAndTime'),
        },
        {
            id: 'code',
            numeric: false,
            label: t('alarms.code'),
        },
        {
            id: 'version',
            numeric: true,
            label: t('alarms.version'),
        },
        {
            id: 'control',
            numeric: false,
            label: t('alarms.control')
        },
    ];
    const numberOfAlarms = alarmsList && alarmsList.length;
    const isLoading = alarmsListIsLoaded && (alarmsList && alarmsList.length > 0) && (alarmHistoryData && alarmHistoryData.length > 0)
        ? false
        : alarmsListIsLoaded && (alarmsList && alarmsList.length === 0)
            ? false
            : true;
    return (React.createElement("div", null,
        React.createElement("div", { className: 'content-title justify-content-space-between' },
            React.createElement("div", { className: 'title' },
                React.createElement("h2", null, device && device.name))),
        React.createElement("div", { className: 'justify-content-space-between mb1' },
            React.createElement("div", { className: 'justify-content-start' },
                React.createElement(Form, { onSubmit: () => { }, render: () => (React.createElement("form", { className: 'justify-content-start' },
                        React.createElement(DateRangePickerComponent, { defaultValue: [start, end], cleanable: false, name: 'DateRange', label: t('analytics.dateRange'), onChangeDate: onChangeDateRange }))) }))),
        React.createElement("p", { className: 'text' },
            t('alarms.alarmsText1'),
            React.createElement("span", { className: 'bold' }, numberOfAlarms && numberOfAlarms),
            t('alarms.alarmsText2')),
        React.createElement(StienenTable, { searchBar: true, showCheckboxes: true, isLoading: isLoading, numberOfRows: 10, headers: headers, rows: alarmHistoryData.length > 0 ? alarmHistoryData : [], defaultOrder: 'timeStamp', defaultOrderDesc: true, title: t('main.alarms') })));
};
export default AlarmsHistory;
